import {DRAW_POLYGON, DRAW_RECTANGLE, SET_CURRENT_POLYGONS, SET_CURRENT_GROUPS,RESET_REDUX} from "./types";

export const drawPolygon = draw => {
    return {
        type: DRAW_POLYGON,
        draw: draw
    }
}

export const drawRectangle = draw => {
  return {
      type: DRAW_RECTANGLE,
      draw: draw
  }
}

export const setCurrentPolygons = polygons => {
    return {
        type: SET_CURRENT_POLYGONS,
        polygons: polygons
    }
}

export const reSetRedux = () => {
    return {
        type: RESET_REDUX
    }
}
export const setCurrentGroups = groups => {
    return {
        type: SET_CURRENT_GROUPS,
        groups: groups
    }
}