import styled from 'styled-components';

// Styled components for the sections
export const RootContainer = styled.div`
  height: 100vh; // Full viewport height
`;

export const TopSection = styled.div`
  height: 92%; // Top section with 2% height
  overflow: hidden;
  background-color: transparent; // Just to visualize the area
  width: -webkit-fill-available;
`;


export const BottomSection = styled.div`
  width: -webkit-fill-available;
overflow: hidden;
  height: 8%; // Bottom section with 8% height
`;
