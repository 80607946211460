import React, { useState, useEffect } from "react";
import {
  Modal,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import { PuffLoader, ClockLoader } from "react-spinners";
import { RiCloseFill } from "react-icons/ri";

const overlayStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const loadingBarStyle = {
  backgroundColor: "rgb(2 31 53 / 97%)",
  color: "#fff",
  padding: "5vh",
  paddingTop: 0,
  borderRadius: "1vh",
  boxShadow: "0px 0.5vh 1vh rgba(0, 0, 0, 0.2)",
  textAlign: "center",
};
const loadingBarStylebg = {
  backgroundColor: "rgb(2 31 53 / 97%)",
  borderRadius: "1vh",
  boxShadow: "0px 0.5vh 1vh rgba(0, 0, 0, 0.2)",
};
const iconStyle = {
  fontSize: "3.5vh",
  cursor: "pointer",
  color: "#fff",
};

const LoadingScreen = ({ onClose, isScaleLoading, start }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if(start){
      setProgress(0)
    }
    let isMounted = true;

    const progressInterval = setInterval(() => {
      if (isMounted) {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 80 : prevProgress + 10
        );
      }
    }, 10000);

    const timeout = setTimeout(() => {
      if (isMounted) {
        clearInterval(progressInterval);
        setProgress(80);
      }
    }, 60000);

    return () => {
      isMounted = false;
      clearInterval(progressInterval);
      clearTimeout(timeout);
    };
  }, [start,isScaleLoading]);

  return (
    <Modal
      open={isScaleLoading}
      onClose={() => {
        onClose(false);
      }}
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal-description"
      style={overlayStyle}
    >
      <div  style={loadingBarStylebg}>
        <div style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start"
        }}>

      <RiCloseFill
          style={iconStyle}
          onClick={() => {
            onClose(false);
          }}
          />
          </div>
      <div style={loadingBarStyle}>
        <Typography
          variant="body1"
          style={{
            marginBottom: "2vh",
            fontSize: "2vh",
            textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
          }}
        >
          Processing Constra Model 1.0...
        </Typography>
        <div style={{ display: "grid", justifyContent: "center" }}>
          <ClockLoader
            color="#56586C"
            loading={true}
            size={8 * window.innerHeight / 100} // Converted size
            aria-label="Loading Spinner"
          />
        </div>

        <ul style={{ listStyle: "none", padding: 0, display: "grid", alignItems: "center", justifyItems: "center" }}>
          <li
            style={{
              textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "space-between",
              fontSize: "1.8vh",
              alignItems: "center",
              marginBottom: "2vh",
              width: "50%"
            }}
          >
            Scale Data processing...
            <PuffLoader
              color="#56586C"
              loading={true}
              size={2 * window.innerHeight / 100} // Converted size
              aria-label="Loading Spinner"
            />
          </li>
          <li
            style={{
              textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "space-between",
              fontSize: "1.8vh",
              marginBottom: "2vh",
              alignItems: "center",
              width: "50%"

            }}
          >
            AI Rooms processing...
            <PuffLoader
              color="#56586C"
              loading={true}
              size={2 * window.innerHeight / 100} // Converted size
              aria-label="Loading Spinner"
            />
          </li>
        </ul>
        <LinearProgress variant="determinate" value={progress} />
        <Typography
          variant="body1"
          style={{
            marginTop: "2vh",
            fontSize: "2vh",
            textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
          }}
        >
          Please wait, this process may take up to 1 minute.
        </Typography>
      </div>
      </div>
    </Modal>
  );
};

export default LoadingScreen;
