import React, { useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: block;
`;

const Target = styled.div`
  position: absolute;
  left: ${(props) => props.offset.left}px;
  top: ${(props) => props.offset.top}px;
  opacity: ${(props) => props.opacity};
  transition: opacity 0.3s;
`;
// z-index:999
//   position: fixed;
//   right: 17vh;
//   top:35vh;
const ModelZoom = ({ children,fromPdf }) => {
  const sourceRefZoom = useRef(null);
  const targetRefZoom = useRef(null);
  const containerRefZoom = useRef(null);

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  const handleMouseEnterZoom = () => {
    setOpacity(1);
  };

  const handleMouseLeaveZoom = () => {
    setOpacity(0);
  };

  const handleMouseMoveZoom = (e) => {
    const targetRect = targetRefZoom.current.getBoundingClientRect();
    const sourceRect = sourceRefZoom.current.getBoundingClientRect();
    const containerRect = containerRefZoom.current.getBoundingClientRect();

    const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
    const yRatio = (targetRect.height - containerRect.height) / sourceRect.height;

    const left = Math.max(Math.min(e.pageX - sourceRect.left, sourceRect.width), 0) -50;
    const top = Math.max(Math.min(e.pageY - sourceRect.top, sourceRect.height), 0) -50;
    const leftX =sourceRect.width < 100 ?(left * -xRatio) : (left * -xRatio)+60
    setOffset({
      left: leftX,
      top: top * -yRatio,
    });
  };

  return (
    <div>
      <Container
        ref={containerRefZoom}
        onMouseEnter={handleMouseEnterZoom}
        onMouseLeave={handleMouseLeaveZoom}
        onMouseMove={handleMouseMoveZoom}
      >
        <div ref={sourceRefZoom} className="pageItem">
          {children}
        </div>
        <Target
          ref={targetRefZoom}
          offset={offset}
          opacity={opacity}
          style={{ transform: fromPdf ? 'scale(2)' : 'scale(1)'}} // You can adjust the scale value to control the zoom level
        >
          {children}
        </Target>
      </Container>
    </div>
  );
};

export default ModelZoom;
