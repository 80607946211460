import {
  DRAW_POLYGON,
  DRAW_RECTANGLE,
  SET_CURRENT_POLYGONS,
  SET_CURRENT_GROUPS,
  RESET_REDUX
} from "../actions/types";

const initState = {
  isDraw: false,
  isRectangle: false,
  currentPolygons: null,
  currentGroups: null,
};

const polygonReducer = (state = initState, action) => {
  switch (action.type) {
    case DRAW_POLYGON: {
      return { ...state, isDraw: action.draw };
    }
    case DRAW_RECTANGLE: {
      return { ...state, isRectangle: action.draw };
    }
    case SET_CURRENT_POLYGONS: {
      return { ...state, currentPolygons: action.polygons };
    }
    case SET_CURRENT_GROUPS: {
      return { ...state, currentGroups: action.groups };
    }
    case RESET_REDUX:
      return initState
    default:
      return state;
  }
};

export default polygonReducer;
