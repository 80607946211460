import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Document, pdfjs, Page } from "react-pdf";
import { useLoaderData } from "react-router-dom";
import {
  setBackgroundLayout,
  setBackgroundLayoutLoading,
  setCurrentPage,
  togglePDF,
} from "../actions/pdfActions";
import { setTool } from "../actions/toolActions";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { BeatLoader } from "react-spinners";
import ModelZoom from "./ModelZoom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
const PagePreview = () => {
  const pdfData = useLoaderData();
  const [selectedPageIndex, setSelectedPageIndex] = useState(null);
  const blobPDFlocal = useSelector((state) => state.pdf.blobPDFlocal);
  const currentPage = useSelector((state) => state.pdf.currentPage);

  const [pageCount, setPageCount] = useState([]);
  const dispatch = useDispatch();

  const onPageClick = (pageNum) => {
    if (selectedPageIndex !== pageNum) {
      dispatch(setBackgroundLayoutLoading(true));
      dispatch(setCurrentPage(pageNum));
      setSelectedPageIndex(pageNum);
      extractImagesFromPDF2(blobPDFlocal, pageNum).then((image) => {
        dispatch(setBackgroundLayout(image));
        dispatch(setBackgroundLayoutLoading(false));
      });
    }
  };

  const onImagePageClick = (pageNum, image) => {
    if (selectedPageIndex !== pageNum) {
      if (blobPDFlocal) {
        onPageClick(pageNum);
      } else {
        setSelectedPageIndex(pageNum);
        dispatch(setCurrentPage(pageNum));
        dispatch(setBackgroundLayoutLoading(true));
        dispatch(setBackgroundLayout(image));
        dispatch(setBackgroundLayoutLoading(false));
      }
    }
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(
      Array(numPages)
        .fill(null)
        .map((_, i) => i + 1)
    );
  };
  useEffect(() => {
    if (pageCount.length === 1) {
      if (currentPage !== 1) {
        onPageClick(1);
        dispatch(setTool("POINTER"));
        dispatch(togglePDF(false));
      }
    }
  }, [pageCount]);
  return (
    <>
      <span
        className="grid-container-background"
        style={{
          color: "white",
          fontSize: "2.3vh",
          width: "-webkit-fill-available",
          textAlign: "center",
        }}
      >
        Please select a residential floor plan page
      </span>
      {blobPDFlocal ? (
        <Document file={blobPDFlocal} onLoadSuccess={onDocumentLoadSuccess}>
          {pageCount.map((page, index) => (
            <div
              key={index}
              className={`grid-item  ${
                currentPage === page && "grid-item-active"
              }`}
              onClick={() => {
                onPageClick(page);
              }}
            >
              <div className="grid-page-title">
                <div className="tick-mark">
                  {" "}
                  {currentPage === page ? (
                    <CheckBoxIcon style={{ color: "green", fontSize: "3vh" }} />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      style={{ color: "gray", fontSize: "3vh" }}
                    />
                  )}
                </div>
                <div style={{ fontSize: "2.2vh" }}>{page}. Page</div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ModelZoom fromPdf>
                  <Page
                    pageNumber={page}
                    width={350}
                    renderAnnotationLayer={false}
                  />
                </ModelZoom>
              </div>
            </div>
          ))}
        </Document>
      ) : (
        <span className="loadingPerview grid-container-background">
          <BeatLoader color="white" loading={true} size={10} />
        </span>
      )}
    </>
  );
};
async function extractImagesFromPDF2(pdfUrl, pageNum) {
  const pdf = await pdfjs.getDocument(pdfUrl).promise;

  const page = await pdf.getPage(pageNum);
  const gridContainer = document.getElementById("gridContainer");
  const originalViewport = page.getViewport({ scale: 1 });
  const scale = Math.min(
    1 + gridContainer?.clientWidth / originalViewport.width,
    1 + gridContainer?.clientHeight / originalViewport.height
  );
  const viewport = page.getViewport({ scale });

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const imageWidth = viewport.width;
  const imageHeight = viewport.height;

  canvas.height = imageHeight;
  canvas.width = imageWidth;

  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };

  await page.render(renderContext).promise;

  return canvas.toDataURL("image/png");
}
export default PagePreview;
