import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosChatboxes } from "react-icons/io";
import { MdOutlineMapsHomeWork } from "react-icons/md";

// Styled components for the button group
const ButtonGroup = styled.div`
  display: inline-flex;
  border: 1px solid rgb(64 63 63);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 7vh;
  font-size: ${(props) => (props.active ? '1.1rem' : '1rem')}; 
  background-color: ${(props) => (props.active ? 'rgb(244 134 38)' : '#403f3f')}; //darkslategrey #403f3f
  color: #fff;
  border: none;
  border-right: ${(props) => (props.last ? 'none' : '1px solid rgb(2 31 53 / 97%)')}; 
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  position: relative;
  font-weight: bold;

  &:hover {
    background-color: "rgb(244 134 38 / 15%)"; 
  }

  &:active {
    transform: scale(0.98); // Button press effect
  }

  // Add subtle shadow for the active button
  ${(props) => props.active && `
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.2);
  `}
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  font-size: 1.5rem; // Icon size
`;

const GroupButton = ({ischatOpen, setChatopen}) => {

  return (
    <ButtonGroup>
       <Button
        active={ischatOpen === 1}
        onClick={() => setChatopen(1)}
        last
      >
        <IconWrapper>
          <IoIosChatboxes />
        </IconWrapper>
        Chat
      </Button>
      <Button
        active={ischatOpen === 0}
        onClick={() => setChatopen(0)}
      >
        <IconWrapper>
          <MdOutlineMapsHomeWork />
        </IconWrapper>
        Scale
      </Button>
    </ButtonGroup>
  );
};

export default GroupButton;
