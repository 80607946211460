import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import view404Error from "../assets/404.jpeg";
import view500Error from "../assets/500.jpeg";
import { Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#FDF9EE",
    flexDirection: "column",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  errorDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },

  img: {
    width: "600px",
  },
}));

const ErrogPage = ({ status }) => {
  const classes = useStyles();

  const statusImage = {
    404: <img className={classes.img} src={view404Error} alt={"404Error"} />,
    500: <img className={classes.img} src={view500Error} alt={"500Error"} />,
  };

  const statusDetails = {
    404: "The page you are looking for could not be found. Or such a pdf has never been uploaded.",
    500: "Something went wrong. We are working to fix it as quickly as possible.",
  };

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        {statusImage[status] || (
          <img className={classes.img} src={view500Error} alt={"500Error"} />
        )}

        <div className={classes.errorDetailsContainer}>
          <Typography>{statusDetails[status]}</Typography>

          <Link to={"/"}>
            {/* <Arrow /> */}
            <Button
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              color="primary"
            >
              GO TO HOME
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ErrogPage;
