import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Button,
  ListItemText,
  Avatar,
  Modal,
  Typography,
  Paper,
  Grid,
  IconButton,
  Tabs,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../actions/auth";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebaseConfig";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  listItemText: {
    color: "white",
    textTransform: "capitalize",
    "& .MuiListItemText-secondary": {
      color: "white",
      textTransform: "none",
      fontSize: "1.5vh",
    },
  },
  avatar: {
    backgroundColor: "#bd5e18",
    // width: "2.5pc",
    // height: "2.5pc",
    // fontSize: "1pc",
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    outline: 'none',
    backgroundColor: "#00011b8a"//"#3e3e3e61"//'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    padding: theme.spacing(4),
    backgroundColor: '#3e3e3ed1',
    borderRadius: 12,
    maxWidth: '80vw',
    maxHeight: '80vh',
    overflowY: 'auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#1c1c1c',
    borderRadius: 8,
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(4),
  },
  tab: {
    minWidth: '50%',
    color: '#9e9e9e',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#bd5e18',
    },
  },
  planItem: {
    padding: theme.spacing(6),
    textAlign: 'center',
    backgroundColor: '#1c1c1c',
    color: '#ffffff',
    borderRadius: 8,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
  },
  planName: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  planDescription: {
    color: '#9e9e9e',
    marginTop: theme.spacing(2),
  },
  planPrice: {
    fontWeight: 'bold',
    color: '#00aaff',
    fontSize: '2rem',
    marginTop: theme.spacing(4),
  },
  planDuration: {
    fontSize: '1rem',
    color: '#9e9e9e',
  },
  subscribeButton: {
    marginTop: theme.spacing(4),
    backgroundColor: '#bd5e18',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'white',
      color: "black"
    },
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#ffff',
  },
}));

const plans = {
  monthly: [
    {
      id: 1,
      name: 'Basic',
      description: 'For early-stage startups that want to spend more time developing and less on manual operations.',
      price: '$400/month',
      duration: 'billed monthly',
    },
    {
      id: 2,
      name: 'Intermediary',
      description: 'For agile startups that want to grow their revenue with quick experiments and data-driven decision making.',
      price: '$600/month',
      duration: 'billed monthly',
    },
    {
      id: 3,
      name: 'Advanced',
      description: 'For fast-growth scaleups that want to grow by maximizing efficiencies in their revenue operations.',
      price: '$750/month',
      duration: 'billed monthly',
    },
  ],
  yearly: [
    {
      id: 1,
      name: 'Basic',
      description: 'For early-stage startups that want to spend more time developing and less on manual operations.',
      price: '$4,000/year',
      duration: 'billed yearly',
    },
    {
      id: 2,
      name: 'Intermediary',
      description: 'For agile startups that want to grow their revenue with quick experiments and data-driven decision making.',
      price: '$6,000/year',
      duration: 'billed yearly',
    },
    {
      id: 3,
      name: 'Advanced',
      description: 'For fast-growth scaleups that want to grow by maximizing efficiencies in their revenue operations.',
      price: '$7,500/year',
      duration: 'billed yearly',
    },
  ],
};

export function UserMenu() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const email = auth?.email;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("yearly");

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setAnchorEl(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function stringAvatar(email) {
    if (!email) return;
    const initials = email.slice(0, 2).toUpperCase();
    return {
      className: classes.avatar,
      children: initials,
    };
  }

  const handleLogout = async () => {
    try {
      await signOut(firebaseAuth);
      dispatch(setAuth(null));
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  };

  return (
    <div>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        id="basic-button"
        onClick={handleClick}
      >
        <Avatar {...stringAvatar(email)} />
      </Button>

      <Menu
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        id="basic-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="webhide">
          <MenuItem>
            <ListItemText secondary={email} />
          </MenuItem>
        </div>

        <MenuItem onClick={handleOpenModal}>Pricing</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalContent}>
            <IconButton className={classes.closeButton} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" style={{color: "white"}} gutterBottom>
              Pricing
            </Typography>
            <Typography variant="body1" align="center" style={{ color: '#9e9e9e', maxWidth: '600px', margin: 'auto' }}>
              Start building for free, then add a site plan to go live. Account plans unlock additional features.
            </Typography>
            <div className={classes.tabsContainer}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="none"
                aria-label="billing options"
              >
                <Tab label="Monthly billing" value="monthly" className={classes.tab} />
                <Tab label="Yearly billing" value="yearly" className={classes.tab} />
              </Tabs>
            </div>
            <Grid container spacing={3} style={{ marginTop: '24px' }}>
              {plans[selectedTab].map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <Paper className={classes.planItem}>
                    <Typography variant="h6" className={classes.planName}>
                      {plan.name}
                    </Typography>
                    <Typography variant="body1" className={classes.planDescription}>
                      {plan.description}
                    </Typography>
                    <Typography variant="h6" className={classes.planPrice}>
                      {plan.price}
                    </Typography>
                    <Typography variant="body2" className={classes.planDuration}>
                      {plan.duration}
                    </Typography>
                    <Button variant="contained" className={classes.subscribeButton}>
                      Subscribe
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}
