import React, { useState } from "react";
import axios from "axios";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
    padding: "2vh",
  },
  mainWrapper: {
    borderRadius: "1vh",
    padding: "2vh",
    paddingBottom: "6vh",
    margin: "1vh",
    width: "43vw",
    boxShadow: "0 0 0.5vh 0.5vh #00000020",
    backdropFilter: "blur(0.5vh)",
    backgroundColor: "#34343485",
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  mainWrapper2: {
    borderRadius: "1vh",
    padding: "4.5vh",
    margin: "1vh",
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  formHead: {
    fontSize: "3vh",
    lineHeight: "4vh",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "2.5vh",
    color: "white",
  },
  formWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1.5vh",
  },
  formCard: {
    position: "relative",
    width: "100%",
  },
  formInput: {
    padding: "2vh 2.5vh 1.5vh",
    width: "100%",
    border: "0.1vh solid #515151",
    borderRadius: "0.5vh",
    background: "transparent",
    outline: "none",
    fontSize: "2vh",
    lineHeight: "3vh",
    color: "white",
    fontWeight: 400,
    boxSizing: "border-box",
    "&:valid, &:focus": {
      border: "0.1vh solid #f9f9f9",
    },
    "&::placeholder": {
      color: "#515151",
    },
  },
  formLabel: {
    position: "absolute",
    left: "2.5vh",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    transition: "0.3s",
    margin: 0,
    fontSize: "1.8vh",
    lineHeight: "2.8vh",
    fontWeight: 500,
  },
  formTextarea: {
    padding: "2vh 2.5vh 1.5vh",
    width: "100%",
    border: "0.1vh solid #515151",
    borderRadius: "0.5vh",
    background: "transparent",
    outline: "none",
    color: "white",
    fontSize: "2vh",
    lineHeight: "3vh",
    fontWeight: 400,
    boxSizing: "border-box",
    resize: "none",
    "&:valid, &:focus": {
      border: "0.1vh solid #f9f9f9",
    },
  },
  btnWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1.6vh",
  },
  submitButton: {
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
    fontSize: "1.8vh",
    lineHeight: "4.8vh",
    border: "0.1vh solid transparent",
    fontWeight: 600,
    width: "10vh",
    height: "5vh",
    borderRadius: "0.6vh",
    transition: "all 0.5s ease",
    cursor: "pointer",
    boxShadow: "0 0 0.5vh 0.5vh #00000020",
    "&:hover": {
      border: "0.1vh solid #000",
      color: "white",
      background: "transparent",
    },
  },
  errorText: {
    color: "red",
    fontSize: "1.4vh",
    marginTop: "0.5vh",
  },
}));

const ContactForm = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    feedback: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formValues.fullName) {
      errors.fullName = "Full Name is required";
    }
    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formValues.feedback) {
      errors.feedback = "Feedback is required";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post('https://constra-api-srdsoovefq-uc.a.run.app/contact-us', {
          name: formValues.fullName,
          email: formValues.email,
          feedBack: formValues.feedback
        });
        toast.success(response.data.message);
        setFormValues({
          fullName: "",
          email: "",
          feedback: ""
        });
        setFormErrors({});
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className={classes.contactContainer} id="Contact">
     
      <div className={classes.mainWrapper}>
        <div className={classes.formHead}>
          <h4 style={{ color: "white" }}>Get in Touch</h4>
        </div>
        <Typography variant="body2" style={{ color: "white", margin: "2vh", fontSize: "2.4vh" }}>
            It is our commitment to ensure a professional and enjoyable experience for you. 
            If you want to leverage AI technology to enhance your operations, click the button below to get started. 
            For any inquiries, feel free to reach out to us using the contact form.
          </Typography>
        <form className={classes.formWrapper} onSubmit={handleSubmit}>
          <div className={classes.formCard}>
            <input
              className={classes.formInput}
              type="text"
              name="fullName"
              placeholder="Full Name"
              required
              value={formValues.fullName}
              onChange={handleChange}
            />
            {formErrors.fullName && (
              <Typography className={classes.errorText}>
                {formErrors.fullName}
              </Typography>
            )}
          </div>

          <div className={classes.formCard}>
            <input
              className={classes.formInput}
              type="email"
              name="email"
              placeholder="Email"
              required
              value={formValues.email}
              onChange={handleChange}
            />
            {formErrors.email && (
              <Typography className={classes.errorText}>
                {formErrors.email}
              </Typography>
            )}
          </div>

          <div className={classes.formCard}>
            <textarea
              className={classes.formTextarea}
              maxLength="420"
              rows="3"
              name="feedback"
              placeholder="Feedback"
              required
              value={formValues.feedback}
              onChange={handleChange}
            />
            {formErrors.feedback && (
              <Typography className={classes.errorText}>
                {formErrors.feedback}
              </Typography>
            )}
          </div>

          <div className={classes.btnWrap}>
            <button type="submit" className={classes.submitButton} disabled={loading}>
              {loading ? (
                <BounceLoader
                  color="#56586C"
                  loading={true}
                  size={25}
                  aria-label="Loading Spinner"
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
