export const SET_MESSAGES_AI = "SET_MESSAGES_AI";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const SET_AI_ROOM_DATA = "SET_AI_ROOM_DATA";
export const SET_AI_SCALE_DATA = "SET_AI_SCALE_DATA";
export const RESET_REDUX = "RESET_REDUX";
export const SET_CURRENT_PDF_FILE_LOCALL = "SET_CURRENT_PDF_FILE_LOCALL";
export const SET_CSV_DATA = "SET_CSV_DATA";
export const SET_TOOL = "SET_TOOL";
export const TOGGLE_PDF = "TOGGLE_PDF";
export const SET_CURRENT_PDF_FİLE = "SET_CURRENT_PDF_FİLE";
export const SET_CURRENT_PDF_PATH = "SET_CURRENT_PDF_PATH";
export const SET_FIRST_UPLOAD = "SET_FIRST_UPLOAD";
export const SET_REFRESH_PDF_DATA_FLAG = "SET_REFRESH_PDF_DATA_FLAG";
export const SET_CURRENT_PDF_ID = "SET_CURRENT_PDF_ID";
export const SET_SCALE_DATA = "SET_SCALE_DATA";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_CURRENT_POLYGONS = "SET_CURRENT_POLYGONS";
export const SET_CURRENT_GROUPS = "SET_CURRENT_GROUPS";
export const DRAW_POLYGON = "DRAW_POLYGON";
export const DRAW_RECTANGLE = "DRAW_RECTANGLE";
export const SET_BACKGROUND_LAYOUT = "SET_BACKGROUND_LAYOUT";
export const SET_BACKGROUND_LAYOUT_LOADING = "SET_BACKGROUND_LAYOUT_LOADING";
export const SET_CURSOR_POSITION = "SET_CURSOR_POSITION";
export const SET_MOUSE_DOWN = "SET_MOUSE_DOWN";
export const SET_ANCHOR = "SET_ANCHOR";
export const UPDATE_EDGES = "UPDATE_EDGES";
export const UPDATE_WALLS = "UPDATE_WALLS";
export const DELETE_WALLS = "DELETE_WALLS";
export const CREATE_WALLS = "CREATE_WALLS";
export const ADD_TEXT = "ADD_TEXT";
export const DELETE_TEXT = "DELETE_TEXT";
export const SET_CUR_SHAPE = "SET_CUR_SHAPE";
export const UPDATE_SELECTED = "UPDATE_SELECTED";
export const SET_SCALE = "SET_SCALE";
export const ADD_OBJECT = "ADD_OBJECT";
export const DELETE_OBJECT = "DELETE_OBJECT";
export const SET_NEW_FILE = "SET_NEW_FILE";
export const UPDATE_OBJECT = "UPDATE_OBJECT";
export const SET_WALL = "SET_WALL";
export const LOAD_FILE = "LOAD_FILE";
export const UPDATE_TEXT = "UPDATE_TEXT";
