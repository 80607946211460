import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import imgDetail2 from '../assets/about1.png';  
import imgDetail3 from '../assets/about2.png';  

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    textAlign: 'center',
  },
  container: {
    // marginBottom: "6vh",
    width: '58%',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  col: {
    flex: '1 1 100%',
  },
  colLg6: {
    [theme.breakpoints.up('md')]: {
      flex: '1 1 45%',
      maxWidth: '45%',
    },
  },
  colLg16: {
    [theme.breakpoints.up('md')]: {
      flex: '1 1 55%',
      maxWidth: '55%',
    },
  },
  sectionTitle: {
    color: 'white',
  },
  sectionSubtitle: {
    margin: 'auto',
    color: 'white',
    letterSpacing: '0.4px',
    lineHeight: '28px',
    maxWidth: '550px',
  },
  aboutHeader: {
    display: "grid",
    marginTop: theme.spacing(3),
  },
  aboutIconBox: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  aboutHeading: {
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: "2.7vh",
    marginTop: theme.spacing(4),
  },
  imgAbout: {
    marginTop: theme.spacing(3),
    maxWidth: '80%',
    height: 'auto',
    margin: '0 auto',
  },
  aboutDetail: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  aboutIcon: {
    fontSize: '22px',
    height: '65px',
    width: '65px',
    lineHeight: '65px',
    display: 'inline-block',
    background: '#fff',
    borderRadius: '35px',
    color: 'rgb(225 120 13 / 69%)',
    boxShadow: '0 8px 20px -2px rgba(158, 152, 153, 0.5)',
    marginBottom: theme.spacing(2),
  },
  textCustom: {
    color: 'rgb(225 120 13 / 69%)',
  },
  imgSize: {
    objectFit: 'cover',
    width: '85%',
    borderRadius: 12,
  },
  imgSize2: {
    objectFit: 'cover',
    width: '100%',
    borderRadius: 12,
  }
}));

const AboutSection = () => {
  const classes = useStyles();

  return (
    <section className={classes.section} id="about">
      <div className={classes.container}>
        <div className={classes.row}>
            <div className={classes.aboutHeader}>
              <h2 className={classes.aboutHeading}>Your Ultimate Construction Cost Estimator.</h2>
              <p className="text-muted mt-3" style={{ color: 'white', fontSize: '2vh' }}>
                Constra.ai is here to revolutionize how you estimate construction
                costs. Our AI-powered platform provides accurate and reliable
                estimates for building houses, ensuring you can plan your projects
                effectively and efficiently.
              </p>
              <p className="text-muted mt-3" style={{ color: 'white', fontSize: '2vh'}}>
                With Constra.ai, you can:
              </p>
              <div className={classes.row}>
                <div className={classes.aboutDetail}>
                  {/* <div className={classes.aboutIcon}>1</div> */}
                  <h5 className="text-dark text-capitalize font-weight-bold" style={{ color: 'white', fontSize: '2vh' }}>
                    Upload PDFs
                  </h5>
                  <p className="edu_desc mt-3 mb-0 text-muted" style={{ color: 'white', fontSize: '2vh' }}>
                    Easily upload your project documents and plans in PDF format. Our advanced AI will analyze
                    them and extract key information to provide precise cost estimations.
                  </p>
                </div>

                <div className={classes.aboutDetail}>
                  {/* <div className={classes.aboutIcon}>2</div> */}
                  <h5 className="text-dark text-capitalize font-weight-bold" style={{ color: 'white', fontSize: '2vh' }}>
                    AI Analysis
                  </h5>
                  <p className="edu_desc mb-0 mt-3 text-muted" style={{ color: 'white', fontSize: '2vh' }}>
                    Leverage the power of artificial intelligence to get detailed breakdowns of materials,
                    labor, and overall project costs. Our algorithms are designed to deliver the most accurate data to support your decision-making.
                  </p>
                </div>

                <div className={classes.aboutDetail}>
                  {/* <div className={classes.aboutIcon}>3</div> */}
                  <h5 className="text-dark text-capitalize font-weight-bold" style={{ color: 'white', fontSize: '2vh' }}>
                    Plan Efficiently
                  </h5>
                  <p className="edu_desc mb-0 mt-3 text-muted" style={{ color: 'white', fontSize: '2vh' }}>
                    Save time and resources by using our platform to streamline your planning process. Our
                    tools help you avoid costly errors and keep your projects on track.
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div className={classes.row}  style={{ marginTop: "3vh"}}>
        <div style={{display: "flex"}}>
              <img src={imgDetail2} alt="About Us" className={classes.imgSize} />
            </div>
            <div>
              <img src={imgDetail3} alt="About Us" className={classes.imgSize2} />
          </div>
          </div>
      </div>
    </section>
  );
};

export default AboutSection;
