// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBwrmPzdsu0SGmjE4J8ykYDICl0KM33mLU",
  authDomain: "constra-73808.firebaseapp.com",
  projectId: "constra-73808",
  storageBucket: "constra-73808.appspot.com",
  messagingSenderId: "1015921801034",
  appId: "1:1015921801034:web:ae8b5fb0a525d6b65133b3"
};
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);

export { firebaseAuth };
