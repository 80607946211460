import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback,
} from "react";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { MdOutlineZoomInMap } from "react-icons/md";

import PolygonAnnotation from "../components/PolygonAnnotation";
import ChatBoot from "./ChatBoot";
import { Stage, Layer, Image, Line, Circle, Group } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { setAiRooms, setCSVData } from "../actions/scaleAndRoomActions";
import {
  setCurrentGroups,
  setCurrentPolygons,
} from "../actions/polygonActions";
import { styled } from "@material-ui/core/styles";
import PageObjects from './PageObjects'
import {
  togglePDF,
  setCurrentPdfPath,
  setRefreshPdfDataFlag,
} from "../actions/pdfActions";
import PagePreview from "./PagePreview";
import { setTool } from "../actions/toolActions";
import { drawPolygon, drawRectangle } from "../actions/polygonActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddIcon from "@material-ui/icons/Add";
import { useParams } from "react-router-dom";
import { RootContainer, TopSection, MiddleSection, BottomSection } from './Sections';

import {
  Button,
  Box,
  TextField,
  Modal,
  Paper,
  Grid,
  Typography,
  InputBase,
  Divider,
  IconButton,
} from "@material-ui/core";
import ToolBar from "../layout/ToolBar";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { toast } from "react-toastify";
import randomColorsList from "../components/randomColors";
import MessageModel from "../components/MessageModel";

import {
  arrayMove,
  sortableKeyboardCoordinates,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableContainer, { Container } from "../components/DragDrop/container";
import SortableItem, { Item } from "../components/DragDrop/sortable_item";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import "./Canvas.css";
import axios from "axios";

const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  // marginTop: 10,
  // backgroundColor: "aliceblue",
};

const dndWrapperStyle = {
  // background: "#e9e9e9",
  // padding: "3px 3px",
  // borderRadius: 8,
  // margin: 50,
};
const FractionalBox = styled(Box)(({ fraction }) => ({
  flexBasis: `calc(${fraction} * 100% / 12)`,
}));
let cancelTokenSource = null;
const AIGeneratedRooms = "AI Generated Rooms";
const Canvas = forwardRef(({ hideRunAILoading,zoomScreen,onClickZoomScreen, ischatOpen,uploadPdfButtonClick }, ref) => {
  const isOpenPerview = useSelector((state) => state.pdf.isOpen);
  const pdfPage = useSelector((state) => state.pdf.backgroundLayout);
  const isDraw = useSelector((state) => state.polygon.isDraw);
  const isRectangle = useSelector((state) => state.polygon.isRectangle);
  const pdfID = useSelector((state) => state.pdf.currentPdfID);
  const pdfPath = useSelector((state) => state.pdf.currentPdf);
  const pdfFile = JSON.parse(useSelector((state) => state.pdf.currentPdfPath));
  // const scaleData = useSelector((state) => state.aiScaleRooms.scaleData);
  const refreshPdfDataFlag = useSelector(
    (state) => state.pdf.refreshPdfDataFlag
  );
  const aiRooms = useSelector((state) => state.aiScaleRooms.aiRooms);
  const firstUpload = useSelector((state) => state.pdf.firstUpload);
  const [image, setImage] = useState();
  const imageRef = useRef(null);
  const polygonRef = useRef(null);
  const drawingPolygon = useRef(null);
  const drawingRectangle = useRef(null);


  const [points, setPoints] = useState([]);
  const [rectanglePoints, setRectanglePoints] = useState([]);
  const [polygonsPoints, setPolygonsPoints] = useState([]);
  const [polygonsFlattenedPoints, setPolygonsFlattenedPoints] = useState([]);
  const [scaleFlattenedPoints, setScaleFlattenedPoints] = useState([]);
  const [size, setSize] = useState({});
  const [flattenedPoints, setFlattenedPoints] = useState();
  const [position, setPosition] = useState([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [selectedPolygonIndex, setSelectedPolygonIndex] = useState("");
  const params = useParams();
  const [newGroupName, setNewGroupName] = useState("");
  const [feetModal, setFeetModal] = useState(false);
  const [numberofPixels, setNumberofPixels] = useState("");
  const [numberofFeet, setNumberofFeet] = useState("");
  const [linePoints, setLinePoints] = useState([]);
  const [inerDragStartMove, setonDragStartMove] = useState(false);
  const [isScaleLoading, setIsScaleLoading] = useState(true);
  const [isMessageModel, setMessageModel] = useState({
    isAiDone: false,
    errorScale: "",
    errorAiRoom: "",
  });
  const [chosenRatio, setChosenRatio] = useState();
  const [currentPageScaleData, setCurrentPageScaleData] = useState({});
  const [lineCoordinates, setLineCoordinates] = useState();
  const [randomColors, setRandomColors] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  // const [refreshDataFlag, setRefreshDataFlag] = useState(false);
  const [showingGroups, setShowingGroups] = useState(["My Rooms"]);
  const [expandedGroups, setExpandedGroups] = useState(["My Rooms"]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pdfFileData, setPdfFileData] = useState(pdfFile);
  const [hasFetched, setHasFetched] = useState(false);
  const [defaultGroupID, setDefaultGroupID] = useState("My Rooms");

  const [currentPageAiRooms, setCurrentPageAiRooms] = useState([]);
  const currentPage = useSelector((state) => state.pdf.currentPage);

  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const polygonIndex = useRef(null);
  const stageRef = useRef(null);
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.08;
    const minScale = 0.8; // Minimum scale set to 80% of the original size

    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };
    let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    newScale = Math.max(newScale, minScale);
    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    setStageScale(newScale);
    setStageX(newPos.x);
    setStageY(newPos.y);
  };
  // const handleWheel = (e) => {
  //   e.evt.preventDefault();

  //   const scaleBy = 1.08;
  //   const stage = stageRef.current;
  //   const oldScale = stage.scaleX();
  //   const pointer = stage.getPointerPosition();
  //   const mousePointTo = {
  //     x: pointer.x / oldScale - stage.x() / oldScale,
  //     y: pointer.y / oldScale - stage.y() / oldScale,
  //   };
  //   const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

  //   setStageScale(newScale);
  //   setStageX(-(mousePointTo.x - pointer.x / newScale) * newScale);
  //   setStageY(-(mousePointTo.y - pointer.y / newScale) * newScale);
  // };
  const handleZoomInOutClick = (InOut) => {
    if (stageRef.current) {
      const scaleBy = 1.2;
      const minScale = 0.8; // Minimum scale set to 80% of the original size
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition();
      if (pointer) {
        const mousePointTo = {
          x: pointer?.x / oldScale - stage?.x() / oldScale,
          y: pointer?.y / oldScale - stage?.y() / oldScale,
        };

        // const newScale = InOut ? oldScale * scaleBy : oldScale / scaleBy;

        let newScale = InOut ? oldScale * scaleBy : oldScale / scaleBy;
        newScale = Math.max(newScale, minScale);
        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };

        setStageScale(newScale);
        setStageX(stage?.x());
        setStageY(newPos.y);
      }
    }
  };
  const handleDoubleClick = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.2;
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    const mousePointTo = {
      x: pointer.x / oldScale - stage.x() / oldScale,
      y: pointer.y / oldScale - stage.y() / oldScale,
    };

    const newScale = oldScale * scaleBy;

    setStageScale(newScale);
    setStageX(-(mousePointTo.x - pointer.x / newScale) * newScale);
    setStageY(-(mousePointTo.y - pointer.y / newScale) * newScale);
  };
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
          setPoints([]);
          setRectanglePoints([]);
      } else if (e.key === "Delete") {
        polygonIndex.current &&
          updatePolygonByPolygonId(polygonIndex.current, "deletePolygon");
      }
    };

    window.addEventListener("keydown", handleEscKey);
    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  const handleStageDragMove = (e) => {
    if (!inerDragStartMove) {
      setStageX(e.target.x());
      setStageY(e.target.y());
    }
  };

  const handleStageDragEnd = (e) => {
    if (!inerDragStartMove) {
      setStageX(e.target.x());
      setStageY(e.target.y());
    } else {
      setonDragStartMove(false);
    }
  };
  //dnd kit
  const [data, setData] = useState({
    items: [
      {
        id: "My Rooms",
        color: "#46B2E1",
        container: true,
      },
    ],
  });
  const [activeId, setActiveId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const dispatch = useDispatch();
  function resizeImage(image) {
    const maxHeight = window.innerHeight * 0.62;
    const maxWidth = window.innerWidth;
    const aspectRatio = image.width / image.height;

    let newWidth = image.width;
    let newHeight = image.height;

    if (image.width > maxWidth || image.height > maxHeight) {
      if (image.width / maxWidth > image.height / maxHeight) {
        newWidth = maxWidth;
        newHeight = maxWidth / aspectRatio;
      } else {
        newHeight = maxHeight;
        newWidth = maxHeight * aspectRatio;
      }
    }

    return { width: newWidth, height: newHeight };
  }
  useEffect(() => {
    const resultFilter = data.items
      ? data.items.filter((word) => word.container)
      : [];
    const result = resultFilter.find((word) => word.id === "My Rooms");
    const defultId =
      result?.id || resultFilter.length > 0 ? resultFilter[0].id : "";
    setDefaultGroupID(defultId);
    if (pdfPage) {
      const img = new window.Image();
      img.src = pdfPage;
      img.onload = () => {
        const { width, height } = resizeImage(img);
        setSize({
          width: width,
          height: height,
        });
        setImage(img);
        imageRef.current = img;
        if (stageRef.current) {
          const stage = stageRef.current;
          const stagePositionWidth = (stage.width() - width) / 2;
          const stagePositionHeight = (stage.height() - height) / 2;
          // stagePositionWidth > 0 &&
          setStageX(stagePositionWidth);
          stagePositionHeight > 0 && setStageY(stagePositionHeight);
        }
        // if (stageRef.current) {
        //   const stage = stageRef.current;
        //   const stagePositionWidth = (stage.width() - width) / 2
        //   stage.position({
        //     x: stagePositionWidth < 0 ? 0 : stagePositionWidth,
        //     y: 0,
        //   });
        //   stage.batchDraw();
        // }
      };
    }
  }, [pdfPage]);
  const uniqueId = () => Math.floor(Math.random() * (9999 - 2000 + 1)) + 2000;
  const findMaxRoomNumber = () => {
    const roomNumbers = data.items
      .filter((room) => room.name && room.name.startsWith("Room"))
      .map((room) => parseInt(room.name.replace("Room ", ""), 10));
    return roomNumbers.length === 0 ? 0 : Math.max(...roomNumbers);
  };
  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    if (pdfPage) {
      setPolygonsPoints([]); // clear polygons created on the page
      setPoints([]); // clear any unfinished polygons on the page
      setRectanglePoints([]);
      setCurrentPageScaleData({});
      setScaleFlattenedPoints([]);
      setPolygonsFlattenedPoints([]);
      setCurrentPageAiRooms([]);
      setChosenRatio(null);
      setLineCoordinates([]);
      setDefaultGroupID("My Rooms");
      dispatch(setCurrentPdfPath(null));
      setDataLoading(true);
    }
    return () => {
      isMounted = false; // Cleanup function to set the flag to false when the component unmounts
    };
  }, [currentPage]);

  useEffect(() => {
    if (currentPageScaleData.length > 0 && size.width && size.height) {
      const scaleX = currentPageScaleData[0].image_width / size.width;
      const scaleY = currentPageScaleData[0].image_height / size.height;
      const chosenRatio = getChosenRatio();
      const lineCoordinates = getScaleLineCoordinates();

      setChosenRatio(chosenRatio / scaleX);
      setLineCoordinates(lineCoordinates);

      const scaleLineCoordinates = lineCoordinates?.map((coord) => ({
        x: coord[0] / scaleX,
        y: coord[1] / scaleY,
      }));

      const combinedArray = scaleLineCoordinates?.flatMap((point) => [
        point.x,
        point.y,
      ]);

      setLinePoints(combinedArray);
    }
  }, [currentPageScaleData, size]);

  useEffect(() => {
    if (
      currentPageAiRooms &&
      currentPageAiRooms.contours_for_rooms &&
      currentPageAiRooms.contours_for_rooms.length > 0 &&
      !dataLoading &&
      pdfFileData &&
      pdfPage &&
      size.width &&
      size.height
    ) {
      const scaleX = currentPageAiRooms.image_width / size.width;
      const scaleY = currentPageAiRooms.image_height / size.height;

      const divisor = [scaleX, scaleY];

      const processContours = (contours, divisor) => {
        return contours.map((room) =>
          room.map((pointArray) =>
            pointArray.map((point) =>
              point.map((coord, index) => coord / divisor[index])
            )
          )
        );
      };

      const processedContours = processContours(
        currentPageAiRooms.contours_for_rooms,
        divisor
      );
      if (
        pdfFileData &&
        currentPageAiRooms &&
        currentPageAiRooms.contours_for_rooms &&
        currentPageAiRooms.contours_for_rooms.length > 0 &&
        !data.items.some((item) => item.id === AIGeneratedRooms) &&
        scaleFlattenedPoints.length === 0
      ) {
        setData((prev) => ({
          items: [
            ...prev.items,
            {
              id: AIGeneratedRooms,
              container: true,
            },
          ],
        }));

        for (let i = 0; i < currentPageAiRooms.contours_for_rooms.length; i++) {
          setData((prev) => ({
            items: [
              ...prev.items,
              {
                id: String(polygonsPoints.length + i),
                parent: AIGeneratedRooms,
                name: `Room ${i + 1}`,
              },
            ],
          }));
        }

        let allFlattanedPoints = [];

        for (let i = 0; i < processedContours.length; i++) {
          allFlattanedPoints.push(processedContours[i].flat(3));
        }

        const pointsWithId = allFlattanedPoints.map((points, index) => ({
          id: String(polygonsPoints.length + index),
          points: [...points],
        }));

        setScaleFlattenedPoints(pointsWithId);
        setUpdateFlag(true);
      } else if (
        // If ai rooms is already assigned to local state but its group is not created
        scaleFlattenedPoints.length > 0 &&
        !data.items.some((item) => item.id === AIGeneratedRooms)
      ) {
        setData((prev) => ({
          items: [
            ...prev.items,
            {
              id: AIGeneratedRooms,
              container: true,
            },
          ],
        }));

        for (let i = 0; i < currentPageAiRooms.contours_for_rooms.length; i++) {
          setData((prev) => ({
            items: [
              {
                id: String(polygonsPoints.length + i),
                parent: AIGeneratedRooms,
                name: `Room ${i + 1}`,
              },
              ...prev.items,
            ],
          }));
        }
        setUpdateFlag(true);
      }
    }
  }, [
    lineCoordinates,
    size,
    currentPageAiRooms,
    currentPage,
    pdfFileData,
    image,
    dataLoading,
    scaleFlattenedPoints,
  ]);

  useEffect(() => {
    if (updateFlag) {
      updatePdfData();
      dispatch(setRefreshPdfDataFlag(true));
      setUpdateFlag(false);
    }
    if (data) {
      dispatch(setCSVData(data.items));
    }
  }, [polygonsPoints, data, updateFlag]);

  useEffect(() => {
    if (refreshPdfDataFlag) {
      getPdfData();
      setRefreshPdfDataFlag(false);
    }
  }, [currentPage]);

  const setDefaultValues = () => {
    setActiveId(null);
    setData({
      items: [
        {
          id: "My Rooms",
          color: "#46B2E1",
          container: true,
        },
      ],
    });
    setImage(null);
    setPoints([]);
    setRectanglePoints([]);
    setPolygonsPoints([]);
    setPolygonsFlattenedPoints([]);
    setScaleFlattenedPoints([]);
    setSize({ width: 0, height: 0 });
    setFlattenedPoints([]);
    setMouseOverPoint(false);
    setPolyComplete(false);
    setSelectedPolygonIndex(null);
    setNewGroupName("");
    setFeetModal(false);
    setNumberofPixels("");
    setNumberofFeet("");
    setLinePoints([]);
    setonDragStartMove(false);
    setIsScaleLoading(true);
    setChosenRatio(null);
    setLineCoordinates(null);
    setUpdateFlag(false);
    setShowingGroups(["My Rooms"]);
    setExpandedGroups(["My Rooms"]);
    setDataLoading(true);
    setPdfFileData(pdfFile);
    setHasFetched(false);
    setDefaultGroupID("My Rooms");
    setCurrentPageAiRooms([]);
    setStageScale(1);
    setStageX(0);
    setStageY(0);
  };

  useEffect(() => {
    const pdfID = window.location.pathname.substring(1);
    if (!pdfFile && !pdfID) {
      setDefaultValues();
    }
  }, [pdfFile]);
  // useEffect(() => {
  //   if (
  //     (polygonsPoints.length > 0 && data.items.length > 1 && !dataLoading) ||
  //     (scaleFlattenedPoints.length > 0 && data.items.length > 1 && !dataLoading)
  //   ) {
  //     updatePdfData();
  //   }
  // }, [polygonsPoints, data]);



  const updatePdfData = async () => {
    try {
      const payload = {
        id: pdfID,
        path: pdfPath,
        page_number: currentPage,
        polygon_points: polygonsPoints,
        polygon_groups: data,
        reorg_ai_rooms: scaleFlattenedPoints,
      };

      await axios.put(
        "https://constra-api-srdsoovefq-uc.a.run.app/pdfs/update",
        payload
      );
    } catch (error) {
      console.error("An error has occurred:", error);
    }
  };

  useEffect(() => {
    if (!pdfFileData && firstUpload) {
      setDataLoading(false);
    }
    if (
      pdfFileData &&
      pdfFileData.scale_data &&
      pdfFileData.scale_data[currentPage]
    ) {
      // const scaleData = JSON.parse(
      //   pdfFileData.scale_data[currentPage].scaleData
      // );
      try {
        const jsonString = pdfFileData.scale_data[currentPage].scaleData;

        const parsedData = JSON.parse(jsonString);
        setCurrentPageScaleData(parsedData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error (e.g., set an error state, show a message to the user, etc.)
      }
    }

    if (
      aiRooms &&
      aiRooms.length < 1 &&
      pdfFileData &&
      pdfFileData.ai_rooms.length > 0
    ) {
      dispatch(setAiRooms(JSON.parse(pdfFileData.ai_rooms)));
      hideRunAILoading(false);
    }
    if (
      pdfFileData &&
      pdfFileData.drawings &&
      pdfFileData.drawings[currentPage]
    ) {
      let groupDataLength =
        pdfFileData.drawings[currentPage].polygonGroups.length;
      let polygonDataLength = pdfFileData.drawings[currentPage].polygons.length;
      const lastGroupData = JSON.parse(
        pdfFileData.drawings[currentPage].polygonGroups[groupDataLength - 1]
      );
      const lastPolygonData = JSON.parse(
        pdfFileData.drawings[currentPage].polygons[polygonDataLength - 1]
      );
      const aiRoomData = JSON.parse(pdfFileData.drawings[currentPage].aiRooms);

      setData(lastGroupData);
      setPolygonsPoints(lastPolygonData);
      setScaleFlattenedPoints(aiRoomData);
      setDataLoading(false);
    } else {
      setData({
        items: [
          {
            id: "My Rooms",
            color: "#46B2E1",
            container: true,
          },
        ],
      }); // clear any groups created on the page
      setDataLoading(false);
    }
  }, [currentPage, firstUpload]);

  // const getAIResponse = useCallback(async () => {
  //   cancelTokenSource && cancelTokenSource.abort();
  //   if (pdfFileData && !hasFetched) {
  //     const { scale_data, ai_rooms } = pdfFileData;
  //     const requests = [];
  //     cancelTokenSource = new AbortController();

  //     if (scale_data.length < 1) {
  //       requests.push({
  //         id: "scale_data",
  //         request: axios.get(`https://model-api-srdsoovefq-uc.a.run.app/get_Scale_fast/${pdfID}`, {
  //           signal: cancelTokenSource.signal
  //         }),
  //       });
  //     }

  //     if (ai_rooms.length < 1) {
  //       requests.push({
  //         id: "ai_rooms",
  //         request: axios.get(`https://model-api-srdsoovefq-uc.a.run.app/get_Rooms_fast/${pdfID}`, {
  //           signal: cancelTokenSource.signal
  //         }),
  //       });
  //     }

  //     if (requests.length > 0) {
  //       try {
  //         const responses = await Promise.all(
  //           requests.map((req) => req.request)
  //         );

  //         responses.forEach((response, index) => {
  //           const requestId = requests[index].id;

  //           if (requestId === "scale_data") {
  //             // setScaleData(response.data);
  //             dispatch(setScaleData(response.data));
  //             updateAIData(response.data, {});
  //           } else if (requestId === "ai_rooms") {
  //             dispatch(setAiRooms(response.data));
  //             // setAiRooms(response.data);
  //             updateAIData({}, response.data);
  //           }
  //         });
  //         hideRunAILoading(false);
  //         setHasFetched(true);
  //       } catch (error) {
  //         toast.dismiss()
  //         error.code !== "ERR_CANCELED" && toast.error("An error has occurred: " + error.message)
  //         console.log("An error has occurred:", error);
  //       }
  //     }
  //   }
  // }, [pdfFileData, hasFetched, pdfID]);
  const getAIResponseSelectedPage = async () => {
    let errorGet = {};
    let errorGet2 = { currentPage, pdfID };
    dispatch(togglePDF(false));
    dispatch(setTool("POINTER"));
    cancelTokenSource && cancelTokenSource.abort();
    const requests = [];
    cancelTokenSource = new AbortController();
    requests.push({
      id: "scale_data",
      request: axios.get(
        `https://model-api.constra.ai/get_Scale_fast/${pdfID}`,
        {
          params: {
            page: currentPage,
          },
          signal: cancelTokenSource.signal,
        }
      ),
    });
    requests.push({
      id: "ai_rooms",
      request: axios.get(
        `https://model-api.constra.ai/get_Rooms_fast/${pdfID}`,
        {
          params: {
            page: currentPage,
          },
          signal: cancelTokenSource.signal,
        }
      ),
    });

    if (requests.length > 0) {
      try {
        const results = await Promise.allSettled(
          requests.map((req) => req.request)
        );

        results.forEach((result, index) => {
          const requestId = requests[index].id;

          if (result.status === "fulfilled") {
            const response = result.value;
            if (response.status === 200) {
              if (requestId === "scale_data") {
                // setScaleData(response.data);
                setCurrentPageScaleData(response.data);
                updateAIData(response.data, {});
                if (Array.isArray(response.data)) {
                  const errMsg =
                    response.data[0]?.for_error_detection_scale
                      ?.reason_for_break;
                  if (errMsg) {
                    errorGet = {
                      ...isMessageModel,
                      isAiDone: true,
                      errorScale: [
                        errMsg,
                        ...response.data[0]?.for_error_detection_scale
                          ?.common_reasons,
                      ],
                    };
                    toast.error(errMsg);
                  } else {
                    toast.success(
                      `Scale Data processing completed successfully!`
                    );
                    errorGet = { ...errorGet, isAiDone: true };
                  }
                }
              } else if (requestId === "ai_rooms") {
                dispatch(setAiRooms([...aiRooms, ...response.data]));
                // setAiRooms(response.data);

                updateAIData({}, response.data);
                if (Array.isArray(response.data)) {
                  const errMsg =
                    response.data[0]?.for_error_detection_scale
                      ?.reason_for_break;
                  if (errMsg) {
                    toast.error(errMsg);
                    errorGet = {
                      ...errorGet,
                      isAiDone: true,
                      errorAiRoom: errMsg,
                    };
                  } else {
                    toast.success(
                      `AI Rooms processing completed successfully!`
                    );
                    errorGet = { ...errorGet, isAiDone: true };
                  }
                }
              }
            } else if (response.status === 500) {
              toast.error(
                `Server error occurred with ${requestId}: ${response.statusText}`
              );
              if (requestId == "ai_rooms") {
                errorGet = {
                  ...errorGet,
                  isAiDone: true,
                  errorAiRoom: response.message,
                };
              }
              console.log(
                `Server error occurred with ${requestId}:`,
                response.statusText
              );
            }
            hideRunAILoading(false);
          } else {
            const error = result.reason;
            hideRunAILoading(error);

            toast.error(
              `An error occurred with ${requestId}: ${error.message}`
            );
            if (requestId == "ai_rooms") {
              errorGet = {
                ...errorGet,
                isAiDone: true,
                errorAiRoom: error.message,
              };
            }
            console.log(`An error occurred with ${requestId}:`, error);
          }
        });
        setMessageModel({ ...errorGet, ...errorGet2 });
        getPdfData();
        setExpandedGroups([AIGeneratedRooms]);
        setShowingGroups([AIGeneratedRooms]);
      } catch (error) {
        hideRunAILoading(false);
        toast.error("An error has occurred: " + error.message);
        console.log("An error has occurred:", error);
      }
    }
  };

  const getPdfData = async () => {
    try {
      const response = await axios.get(
        `https://constra-api-srdsoovefq-uc.a.run.app/pdfs/${pdfID}`
      );
      setPdfFileData(response.data);
      dispatch(setCurrentPdfPath(JSON.stringify(response.data)));
    } catch (error) {
      console.error("An error has occurred:", error);
    }
  };

  // useEffect(() => {
  //   getAIResponse();
  // }, []);

  // useEffect(() => {
  //   getPdfData();
  // }, [currentPage]);

  useEffect(() => {
    // Whenever a page changes, it takes the relevant AI response for that page and assigns it to the state
    if (aiRooms) {
      const pageAiRoomsData = aiRooms.find(
        (data) => data.page_id === currentPage - 1
      );
      setCurrentPageAiRooms(pageAiRoomsData);
    }
  }, [currentPage, aiRooms]);

  const updateAIData = async (scale_data, ai_rooms) => {
    // when comes scaleData or aiRooms saving the database accordingly pdf

    const payload = {
      id: pdfID,
      page_number: currentPage,
    };

    scale_data.length && (payload.scale_data = scale_data);
    ai_rooms.length && (payload.ai_rooms = JSON.stringify(ai_rooms));

    try {
      await axios.put(
        "https://constra-api-srdsoovefq-uc.a.run.app/pdfs/update",
        payload
      );
    } catch (error) {
      console.error("An error has occurred:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      // If clicked outside konvajs-content
      if (!e.target.closest(".konvajs-content")) {
        // If clicked inside canvasDataBox or modal paper, return without performing draw actions
        if (
          e.target.closest(".canvasDataBox") ||
          e.target.closest(".MuiPaper-root")
        ) {
          return;
        }
        // If draw mode is on, turn off drawing mode, reset points, and reset poly completion state
        if (isDraw) {
          setPoints([]);
          setPolyComplete(false);
        } else if (isRectangle) {
          setRectanglePoints([]);
          setPolyComplete(false);
        }
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup function to remove event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDraw, isRectangle]);

  const getMousePos = (stage) => {
    if (stageX && stageY) {
      const pointerPosition = stage.getPointerPosition();
      const x = (pointerPosition.x - stageX) / stageScale;
      const y = (pointerPosition.y - stageY) / stageScale;
      return [x, y];
    } else {
      return [stage.getPointerPosition().x, stage.getPointerPosition().y];
    }
  };

  // Handle the mousedown event to start or complete a shape
  const handleMouseDown = (e) => {
    const stage = e.target.getStage();

    const mousePos = getMousePos(stage);

    if (isDraw) {
      handlePolygonDrawing(mousePos);
    } else if (isRectangle) {
      if (rectanglePoints.length === 0) {
        // Start rectangle drawing
        setRectanglePoints([mousePos, mousePos]);
      } else if (rectanglePoints.length === 2) {
        // Complete the rectangle drawing
        setPolyComplete(true);
        handleRectangleComplete(mousePos);
      }
    }
  };

  // Handle the mousemove event to update the second point of the rectangle
  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isRectangle && rectanglePoints.length > 1) {
      // Calculate the four corners of the rectangle relative to the initial point
      const startX = rectanglePoints[0][0];
      const startY = rectanglePoints[0][1];
      const endX = mousePos[0];
      const endY = mousePos[1];

      const rectPolygonPoints = [
        [startX, startY], // Top-left
        [endX, startY], // Top-right
        [endX, endY], // Bottom-right
        [startX, endY], // Bottom-left
      ];
      // Update the rectangle flattened points for rendering
      if (drawingRectangle.current && isRectangle) {
        drawingRectangle.current.points(rectPolygonPoints.flat());
      }
    }
      // Update the polygon flattened points for rendering
    if (drawingPolygon.current && isDraw) {
      drawingPolygon.current.points(points.flat().concat(mousePos));
    }
  };


  // Finalize the rectangle and store it as a polygon
  const handleRectangleComplete = (mousePos) => {
    const rectPolygonPoints = [
      rectanglePoints[0],
      [mousePos[0], rectanglePoints[0][1]],
      mousePos,
      [rectanglePoints[0][0], mousePos[1]],
    ];

    const uniqueIds = uniqueId();

    setData((prev) => ({
      items: [
        {
          id: String(uniqueIds),
          parent: defaultGroupID,
          name: `Room ${String(findMaxRoomNumber() + 1)}`,
        },
        ...prev.items,
      ],
    }));

    setPolygonsPoints((prevPolygonsPoints) => {
      const newPolygonsPoints = [
        ...prevPolygonsPoints,
        {
          id: String(uniqueIds),
          points: rectPolygonPoints,
        },
      ];
      setUpdateFlag(true);
      return newPolygonsPoints;
    });

    // Reset rectanglePoints to start drawing a new rectangle
    setPoints([]);
    setRectanglePoints([]);
    setPolyComplete(false);
  };


  const handlePolygonDrawing = (mousePos) => {
    if (isPolyComplete) return;

    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true);
      // When starting a new polygon, save the points and flattened points of the existing polygon to the state

      // setPolygonsPoints([
      //   ...polygonsPoints,
      //   {
      //     id: String(
      //       scaleFlattenedPoints.length === 0
      //         ? polygonsPoints.length
      //         : scaleFlattenedPoints.length + polygonsPoints.length + 1
      //     ),
      //     points: [...points],
      //   },
      // ]);
      const uniqueIds = uniqueId();
      setData((prev) => ({
        items: [
          {
            id: String(uniqueIds),
            parent: defaultGroupID,
            name: `Room ${String(findMaxRoomNumber() + 1)}`,
          },
          ...prev.items,
        ],
      }));

      setPolygonsPoints((prevPolygonsPoints) => {
        const newPolygonsPoints = [
          ...prevPolygonsPoints,
          {
            id: String(uniqueIds),
            points: [...points],
          },
        ];
        setUpdateFlag(true);
        return newPolygonsPoints;
      });

      // When starting a new polygon, clear the points used for drawing
      setPoints([]);
      setRectanglePoints([]);
      setPolyComplete(false);
    } else {
      setPoints([...points, mousePos]);
    }
  };

  const handleMouseOverStartPoint = (e) => {
    if (!isDraw) e.target.scale({ x: 3, y: 3 });

    if (isPolyComplete || points.length < 3) return;
    const threshold = 10;
    const pos = [e.target.x(), e.target.y()];
    const startPointX = points[0][0];
    const startPointY = points[0][1];

    // If the mouse position is close to the starting point, perform the action
    if (
      Math.abs(pos[0] - startPointX) < threshold &&
      Math.abs(pos[1] - startPointY) < threshold
    ) {
      e.target.scale({ x: 3, y: 3 });
      setMouseOverPoint(true);
    }
  };

  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };
  const handlePointDragMove = (e) => {
    // Works when polygon points are dragged
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = getMousePos(stage);
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();

    const polygonIndex = e.target.getParent().index - 1;

    const updatedPolygonsPoints = [...polygonsPoints];

    updatedPolygonsPoints[polygonIndex].points[index] = pos;

    if (polygonRef.current) {
      polygonRef.current.points(
        updatedPolygonsPoints[polygonIndex].points.flat()
      );
    }
  };

  const handlePointDragEnd = (e) => {
    setUpdateFlag(true);
  };

  useEffect(() => {
    // Update Redux store with the latest polygon points and groups data
    // updateReduxPolygonsPoints(); // It was integrated with the save button in the AppBar, we don't need it right now.

    // Create a new array to store the flattened points of all polygons
    const updatedPolygonsFlattenedPoints = polygonsPoints.map((polygon) => {
      return polygon.points.reduce((a, b) => a.concat(b), []);
    });

    // Handle the ongoing rectangle drawing points
    // if (rectanglePoints.length === 2) {

    //   const rectPolygonPoints = [
    //     rectanglePoints[0],
    //     [rectanglePoints[1][0], rectanglePoints[0][1]], // Top-right corner
    //     rectanglePoints[1], // Bottom-right corner
    //     [rectanglePoints[0][0], rectanglePoints[1][1]], // Bottom-left corner
    //   ];

    //   updatedPolygonsFlattenedPoints.push(
    //     rectPolygonPoints.reduce((a, b) => a.concat(b), [])
    //   );
    // }

    // // Update the state with the newly calculated flattened points
     setPolygonsFlattenedPoints(updatedPolygonsFlattenedPoints);
  }, [polygonsPoints, isPolyComplete]);

  useEffect(() => {
    if (isDraw || isRectangle) {
      let currentPoints;

      if (isRectangle && rectanglePoints.length === 2) {
        const rectPolygonPoints = [
          rectanglePoints[0],
          [rectanglePoints[1][0], rectanglePoints[0][1]],
          rectanglePoints[1],
          [rectanglePoints[0][0], rectanglePoints[1][1]],
        ];
        currentPoints = rectPolygonPoints;
      } else if (isDraw) {
        currentPoints = points.concat([]);
      }

      if (currentPoints) {
        setFlattenedPoints(currentPoints.reduce((a, b) => a.concat(b), []));
      }
    }
  }, [points, rectanglePoints, isPolyComplete, isRectangle, isDraw]);

  useEffect(() => {
    if ((polygonsPoints || scaleFlattenedPoints) && pdfPage && chosenRatio) {
      calculateAllPolygonsFeet();
    }
  }, [polygonsPoints, scaleFlattenedPoints, chosenRatio]);

  const handleGroupDragEnd = (e) => {
    // Function for dragging completed polygon
    if (e.target.name() === "polygon") {
      const polygonGroup = e.target;
      const polygonIndex = polygonGroup.index - 1;
      const offsetX = polygonGroup.x();
      const offsetY = polygonGroup.y();

      const updatedPolygonsPoints = [...polygonsPoints];

      const updatedPolygonPoints = updatedPolygonsPoints[
        polygonIndex
      ].points.map((point) => [point[0] + offsetX, point[1] + offsetY]);

      updatedPolygonsPoints[polygonIndex].points = updatedPolygonPoints;
      setPolygonsPoints(updatedPolygonsPoints);

      setUpdateFlag(true);

      // Resetting the position of the polygon group
      polygonGroup.position({ x: 0, y: 0 });
    }
  };

  const updateReduxPolygonsPoints = () => {
    dispatch(setCurrentPolygons(polygonsPoints));
    dispatch(setCurrentGroups(data));
  };

  const handlePolygonClick = (index) => {
    polygonIndex.current = index;
    setSelectedPolygonIndex(index);
  };

  const handleGroupNameChange = (event) => {
    setNewGroupName(event.target.value);
  };

  const updateGroupByGroupId = (groupId, action, payload) => {
    setData((prevData) => ({
      ...prevData,
      items: prevData.items
        .map((item) => {
          switch (action) {
            case "updateColor":
              return item.id === groupId
                ? { ...item, color: payload.newColor }
                : item;
            case "updateName":
              if (item.id === groupId) {
                return { ...item, id: payload.newName };
              } else if (item.parent === groupId) {
                return { ...item, parent: payload.newName };
              } else {
                return item;
              }
            case "deleteGroup":
              return item.parent === groupId
                ? { ...item, parent: "My Rooms" }
                : item;
            default:
              return item;
          }
        })
        .filter((item) => action !== "deleteGroup" || item.id !== groupId),
    }));
    setUpdateFlag(true);
  };

  const updatePolygonByPolygonId = (polygonId, action, payload) => {
    setData((prevData) => {
      let updatedItems;

      switch (action) {
        case "updateColor":
          updatedItems = prevData.items.map((item) =>
            item.id === polygonId ? { ...item, color: payload.newColor } : item
          );
          break;

        case "updateName":
          updatedItems = prevData.items.map((item) =>
            item.id === polygonId ? { ...item, name: payload.newName } : item
          );
          break;

        case "deletePolygon":
          updatedItems = prevData.items.filter((item) => item.id !== polygonId);
          setPolygonsPoints((prevPolygonPoints) =>
            prevPolygonPoints.filter((polygon) => polygon.id !== polygonId)
          );
          setScaleFlattenedPoints((prevScaleFlattenedPoints) =>
            prevScaleFlattenedPoints.filter(
              (polygon) => polygon.id !== polygonId
            )
          );
          break;

        default:
          updatedItems = prevData.items;
      }
      return {
        ...prevData,
        items: updatedItems,
      };
    });

    setUpdateFlag(true);
  };

  const getParentColorById = (id) => {
    const item = data.items.find((item) => item.id === id);

    if (!item || !item.parent) {
      return null;
    }
    const parentId = item.parent;
    const parent = data.items.find((parentItem) => parentItem.id === parentId);

    if (!parent) {
      return null;
    }

    return parent.color;
  };

  const factorCalculate = () => {
    handleFeetModal();
  };

  const handleRunAI = () => {
    if (isScaleLoading) {
      // setIsScaleLoading(
      //   toast.loading("Please wait, we are running the AI modal...")
      // );
    } else {
      calculateAllPolygonsFeet();
    }
  };

  const getChosenRatio = () => {
    let scale = currentPageScaleData[0];

    return scale.chosen_ratio;
    // if (scale && scale.chosen_ratio) {
    //   return scale.chosen_ratio;
    // } else {
    //   for (let i = currentPage - 1; i >= 0; i--) {
    //     scale = scaleData.find((item) => item.page_id === i);
    //     if (scale && scale.chosen_ratio) {
    //       return scale.chosen_ratio;
    //     }
    //   }
    // }
  };

  const getScaleLineCoordinates = () => {
    let scale = currentPageScaleData[0];

    return scale.line_coordinates;

    // if (scale && scale.line_coordinates) {
    //   return scale.line_coordinates;
    // } else {
    //   for (let i = currentPage - 1; i >= 0; i--) {
    //     scale = scaleData.find((item) => item.page_id === i);
    //     if (scale && scale.line_coordinates) {
    //       return scale.line_coordinates;
    //     }
    //   }
    // }
  };

  const calculateAllPolygonsFeet = async () => {
    if (chosenRatio) {
      const newData = { ...data };

      const allPolygonsPoints = [...polygonsPoints, ...scaleFlattenedPoints];

      for (let j = 0; j < allPolygonsPoints.length; j++) {
        const OnePolygonPoints = allPolygonsPoints[j].points;
        const polygonFeet = handleCalculatePolygonFeet(
          OnePolygonPoints,
          chosenRatio
        );

        const polygonId = allPolygonsPoints[j].id;

        const itemIndex = newData.items.findIndex(
          (item) => item.id === polygonId
        );
        if (itemIndex !== -1) {
          newData.items[itemIndex].feet = polygonFeet;
        }
      }
      setData(newData);
    }
  };

  const calculateDistance = (point1, point2) => {
    const [x1, y1] = point1;
    const [x2, y2] = point2;
    const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    return distance;
  };

  const handleFeetModal = () => {
    setFeetModal(!feetModal);
  };

  const handleFeetChange = (event) => {
    setNumberofFeet(event.target.value);
  };

  const calculatePolygonArea = (input) => {
    let points = [];

    if (typeof input[0] === "number") {
      for (let i = 0; i < input.length; i += 2) {
        points.push([input[i], input[i + 1]]);
      }
    } else {
      points = input;
    }

    let area = 0;
    const n = points.length;
    for (let i = 0; i < n; i++) {
      const [x1, y1] = points[i];
      const [x2, y2] = points[(i + 1) % n];
      area += x1 * y2 - x2 * y1;
    }
    return Math.abs(area) / 2;
  };

  const handleCalculatePolygonFeet = (polygon, chosen_ratio) => {
    const polygonArea = calculatePolygonArea(polygon);
    const pixelPerFeet = chosen_ratio * 12;
    const realArea = polygonArea / pixelPerFeet ** 2;
    return realArea;
  };

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 156) + 100;
    const b = Math.floor(Math.random() * 100);
    return `rgb(${r},${g},${b})`;
  };

  useEffect(() => {
    const randomColors = [];

    for (let i = 0; i < scaleFlattenedPoints.length; i++) {
      let clr = randomColorsList[i];
      randomColors.push(clr || getRandomColor());
    }
    setRandomColors(randomColors);
  }, [scaleFlattenedPoints]);

  const handleLineClick = () => {
    const point1 = linePoints.slice(0, 2);
    const point2 = linePoints.slice(2, 4);
    const distance = calculateDistance(point1, point2) + 14;
    setNumberofPixels(distance);
    handleFeetModal();
  };

  const toggleGroupVisibility = (groupId) => {
    if (groupId === AIGeneratedRooms) {
      setShowingGroups((prevShowingGroups) => {
        if (prevShowingGroups.includes(groupId)) {
          return prevShowingGroups.filter((group) => group !== groupId);
        } else {
          return [AIGeneratedRooms];
        }
      });
    } else {
      setShowingGroups((prevShowingGroups) => {
        if (prevShowingGroups.includes(groupId)) {
          return prevShowingGroups.filter((group) => group !== groupId);
        } else {
          return [
            ...prevShowingGroups.filter((group) => group !== AIGeneratedRooms),
            groupId,
          ];
        }
      });
    }
  };

  const toggleExpandedGroup = (groupId) => {
    if (!expandedGroups.includes(groupId)) {
      setExpandedGroups((prevExpandedItem) => [...prevExpandedItem, groupId]);
    } else {
      setExpandedGroups((prevExpandedItem) =>
        prevExpandedItem.filter((item) => item !== groupId)
      );
    }
  };

  const toggleDefaultGroup = (groupId) => {
    if (defaultGroupID !== groupId) {
      setDefaultGroupID(groupId);
    }
  };

  useImperativeHandle(ref, () => ({
    getAIResponseSelectedPage,
    cancelTokenSource,
  }));
  const stageWidth = size.width;
  const radius = 7;
  const transformWrapperRef = useRef(null);
  useEffect(() => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform();
    }
  }, []);
  useEffect(() => {
    if (isPolyComplete) {
      dispatch(setTool("POINTER"));
      dispatch(drawPolygon(false));
      dispatch(drawRectangle(false));
      setShowingGroups([
        ...showingGroups.filter((group) => group !== AIGeneratedRooms),
        defaultGroupID,
      ]);
      setExpandedGroups((prevExpandedItem) => [
        ...prevExpandedItem,
        defaultGroupID,
      ]);
    }
  }, [isPolyComplete]);
  return (
    <div style={wrapperStyle}>
      <MessageModel
        isScaleLoading={isMessageModel.isAiDone}
        error={isMessageModel}
        onClose={() =>
          setMessageModel({ isAiDone: false, errorScale: "", errorAiRoom: "" })
        }
      />
      <Grid
        container
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FractionalBox
          id="Tools"
          fraction={2.5}
          className="shadowsColumn containerColl grid-center-main"
        >
          <>
            <span
              className="modelTitle"
              style={{
                color: "white",
                fontWeight: "bold",
                opacity: 0.5,
              }}
            >
              OBJECTS
            </span>
            <div style={{padding:5, width:"-webkit-fill-available"}}>

          <PageObjects />
            </div>
          </>
        </FractionalBox>
        <FractionalBox
          id="MODEL"
          fraction={6.5}
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          className={`shadowsColumn containerColl grid-center-main ${!ischatOpen && "overflowHidden"}`}
        >
          {ischatOpen ? <ChatBoot uploadPdfButtonClick={() =>{uploadPdfButtonClick("s")}}/>:
          <>
          <div
            style={{
              display: "grid",
              width: "100%",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <span>
            </span>
            <span
              className="modelTitle"
              style={{
                color: "white",
                fontWeight: "bold",
                opacity: 0.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isOpenPerview ? "PREVIEW" : "MODEL"}
            </span>
            <span
              onClick={() => {
                isOpenPerview ? dispatch(togglePDF(false)): onClickZoomScreen()
              }}
              className="perviewCencel"
              style={{
                paddingRight: 2,
                justifyContent: "flex-end",
              }}
            >
              {isOpenPerview ? (
                <HighlightOffIcon style={{ fontSize: "3.2vh" }} />
              ):(
                <>
                {zoomScreen ? <MdOutlineZoomOutMap style={{ fontSize: "3.2vh" }} />:
                <MdOutlineZoomInMap style={{ fontSize: "3.2vh" }} />}
                </>
              )}
            </span>
          </div>
          {isOpenPerview && <PagePreview />}

          {!isOpenPerview && <> <TopSection>
          <div style={isOpenPerview ? { display: "none" } : {}}>
            {pdfPage && (
              <Stage
                width={zoomScreen ? window.innerWidth * 0.43413749999999995: window.innerWidth || 650}
                height={window.innerHeight * 0.69 || 302}
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                ref={stageRef}
                onWheel={handleWheel}
                onDblClick={handleDoubleClick}
                scaleX={stageScale}
                scaleY={stageScale}
                x={stageX}
                y={stageY}
                draggable={!inerDragStartMove}
                onDragMove={handleStageDragMove} // Highlighted: onDragMove event handler
                onDragEnd={handleStageDragEnd} // Highlighted: onDragEnd event handler
              >
                <Layer>
                  <Image
                    ref={imageRef}
                    image={image}
                    x={0}
                    y={0}
                    width={size.width}
                    height={size.height}
                  />

                  {/* Polygon Drawing */}
                  {points && isDraw && (
                    <PolygonAnnotation
                      points={points}
                      polygonRef={drawingPolygon}
                      flattenedPoints={flattenedPoints}
                      handlePointDragMove={handlePointDragMove}
                      handlePointDragEnd={handlePointDragEnd}
                      handleGroupDragEnd={handleGroupDragEnd}
                      handleMouseOverStartPoint={handleMouseOverStartPoint}
                      handleMouseOutStartPoint={handleMouseOutStartPoint}
                      isFinished={isPolyComplete}
                      isDraw={isDraw}
                      fillColor={
                        data.items.find((item) => item.id === selectedPolygonIndex)?.color
                      }
                    />
                  )}

                  {/* Rectangle Drawing */}
                  {rectanglePoints.length === 2 && (
                   <PolygonAnnotation
                     points={[
                       rectanglePoints[0],
                       [rectanglePoints[1][0], rectanglePoints[0][1]],
                       rectanglePoints[1],
                       [rectanglePoints[0][0], rectanglePoints[1][1]],
                     ]}
                     polygonRef={drawingRectangle}
                     flattenedPoints={flattenedPoints}
                     handlePointDragMove={handlePointDragMove}
                     handlePointDragEnd={handlePointDragEnd}
                     handleGroupDragEnd={handleGroupDragEnd}
                     handleMouseOverStartPoint={handleMouseOverStartPoint}
                     handleMouseOutStartPoint={handleMouseOutStartPoint}
                     isFinished={true}
                     isDraw={isRectangle}
                     fillColor={
                       data.items.find((item) => item.id === selectedPolygonIndex)?.color || getParentColorById(selectedPolygonIndex)
                     }
                    />
                  )}

                {/* Virtual Rectangle */}
                {isRectangle && rectanglePoints.length > 1 && (
                     <Line
                     points={flattenedPoints}
                     stroke="#A9A9A9"
                     strokeWidth={0.5}
                     closed={true}
                     opacity={0.1}
                    />
                )}

                  {/* Render Existing Polygons */}
                  {polygonsPoints.map((polygonPoints, index) => {
                    let groupName;
                    let visible = true;

                    if (data.items) {
                      const foundGroup = data.items.find(
                        (group) => group.id === polygonPoints.id
                      );
                      if (foundGroup) {
                        groupName = foundGroup.parent;
                      }
                    }

                    if (!showingGroups.includes(groupName)) {
                      visible = false;
                    }

                    return (
                      <PolygonAnnotation
                        key={index}
                        polygonRef={polygonRef}
                        pdfSize={size}
                        onDragStartMove={() => setonDragStartMove(true)}
                        points={polygonPoints.points}
                        flattenedPoints={polygonsFlattenedPoints[index]}
                        handlePointDragMove={handlePointDragMove}
                        handlePointDragEnd={handlePointDragEnd}
                        handleGroupDragEnd={handleGroupDragEnd}
                        handleMouseOverStartPoint={handleMouseOverStartPoint}
                        handleMouseOutStartPoint={handleMouseOutStartPoint}
                        isFinished={true}
                        isDraw={isDraw}
                        onClick={() => {
                          handlePolygonClick(polygonPoints.id);
                        }}
                        visible={visible}
                        selected={selectedPolygonIndex === polygonPoints.id}
                        fillColor={
                          data.items.find(
                            (item) => item.id === polygonPoints.id
                          )?.color || getParentColorById(polygonPoints.id)
                        }
                      />
                    );
                  })}

                  {/* Render AI Generated Rooms */}
                  {scaleFlattenedPoints.map((flattenedPoint, index) => {
                    let groupName;

                    if (data.items) {
                      const foundGroup = data.items.find(
                        (group) => group.id === flattenedPoint.id
                      );

                      if (foundGroup) {
                        groupName = foundGroup.parent;
                      }
                    }

                    if (!showingGroups.includes(groupName)) {
                      return null;
                    }

                    const isAIGeneratedRoom = data.items.some(
                      (item) =>
                        item.id === flattenedPoint.id &&
                        item.parent === AIGeneratedRooms
                    );

                    const fill =
                      flattenedPoint.id === selectedPolygonIndex
                        ? "#FFD700"
                        : (() => {
                            const item = data.items.find(
                              (item) => item.id === flattenedPoint.id
                            );

                            return isAIGeneratedRoom
                              ? item.color || randomColors[index]
                              : randomColors[index];
                          })();

                    return (
                      <Group key={index} draggable={false}>
                        <Line
                          points={flattenedPoint.points}
                          stroke={"#A9A9A9"}
                          strokeWidth={1}
                          closed={true}
                          opacity={0.6}
                          fill={fill}
                          onClick={() => {
                            handlePolygonClick(flattenedPoint.id);
                          }}
                        />
                      </Group>
                    );
                  })}

                  {/* Uncommented original code for lines and circles */}
                  {/* <Line
                    className="line"
                    points={linePoints}
                    stroke="rgb(71, 127, 179)"
                    fill="black"
                    strokeWidth={7}
                    closed={true}
                    onMouseMove={handleMouseMove}
                    onClick={handleLineClick}
                  />
                  <Circle
                    x={linePoints[0]}
                    y={linePoints[1]}
                    radius={6}
                    fill="rgb(71, 127, 179,0.4)"
                    stroke="red"
                    dragBoundFunc={(pos) => {
                      const newX = Math.max(radius, Math.min(pos.x, stageWidth - radius));
                      return {
                        x: newX,
                        y: linePoints[1],
                      };
                    }}
                    onDragMove={(event) => {
                      const newPoints = [...linePoints];
                      newPoints[0] = event.target.x();
                    }}
                  />
                  <Circle
                    x={linePoints[2]}
                    y={linePoints[3]}
                    radius={6}
                    fill="rgb(71, 127, 179,0.4)"
                    stroke="red"
                    dragBoundFunc={(pos) => {
                      const newX = Math.max(radius, Math.min(pos.x, stageWidth - radius));
                      return {
                        x: newX,
                        y: linePoints[3],
                      };
                    }}
                    onDragMove={(event) => {
                      const newPoints = [...linePoints];
                      newPoints[2] = event.target.x();
                    }}
                  /> */}
                </Layer>
              </Stage>
            )}
          </div>
      </TopSection>
      <BottomSection>
      <ToolBar
       uploadPdfButtonClick={() =>{uploadPdfButtonClick("s")}}
              ZoomInClick={() => {
                handleZoomInOutClick(true);
              }}
              ZoomOutClick={() => {
                handleZoomInOutClick(false);
              }}
            />
      </BottomSection></>}
          </>}
        
        </FractionalBox>
        {/* <Grid item xs={2} className="shadowsColumn containerColl grid-center-main"> */}
        <FractionalBox
          id="MEASUREMENTS"
          fraction={2.5}
                className="shadowsColumn containerColl grid-center-main"

        >
          
            <span
            className="modelTitle"
            style={{
              color: "white",
              fontWeight: "bold",
              opacity: 0.5,
            }}
          >
            MEASUREMENTS
          </span>
          {pdfPage && (
            <Paper
              style={{
                paddingLeft: 9,
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
                marginTop: 5,
                height: 35,
                marginRight: 3,
                width: "90%",
              }}
            >
              <InputBase
                value={newGroupName}
                onChange={handleGroupNameChange}
                id="newGroupName"
                style={{
                  marginLeft: 1,
                  fontSize: 12,
                  flex: 1,
                }}
                placeholder="New Group Name"
              />
              <Divider
                style={{
                  height: 28,
                }}
                orientation="vertical"
              />
              <IconButton
                onClick={() => {
                  const exists = data.items.some(
                    (item) => item.container && item.id === newGroupName
                  );
                  if (!exists) {
                    addItem(newGroupName, true, generateColor(data));
                  } else {
                    toast.warn(`Group Name ${newGroupName} already exists.`);
                  }
                }}
                disabled={!newGroupName.trim()}
                color="primary"
                style={{
                  padding: 10,
                }}
                aria-label="directions"
              >
                <AddIcon />
              </IconButton>
            </Paper>
          )}
          <Box className="canvasDataBox" id="canvasDataBox">
            {pdfPage && (
              <>
                <div className="canvasDataBoxHeader">
                  {/* <div className="canvasDataBoxHeaderMain"> */}
                  {/* <h3
                  className="canvasDataBoxTitle"
                  style={{
                    color: "white",
                  }}
                >
                  Measurements
                </h3> */}
                  {/* <div className="canvasDataBoxButtonsContainer">
                  <Button
                    style={{
                      backgroundColor: "#e5e5e5",
                      color: "black",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleRunAI}
                    //disabled={!numberofFeet}
                  >
                    RUN AI
                  </Button>
                </div> */}
                  {/* </div> */}
                </div>
                <DndContext
                  sensors={sensors}
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDragEnd={handleDragEnd}
                  strategy={closestCorners}
                >
                  <SortableContext
                    id="root"
                    items={getItemIds()}
                    strategy={verticalListSortingStrategy}
                  >
                    <div style={dndWrapperStyle}>
                      {getItems().map((item) => {
                        if (item.container) {
                          return (
                            <SortableContainer
                              key={item.id}
                              id={item.id}
                              color={item.color}
                              updateGroupByGroupId={updateGroupByGroupId}
                              updatePolygonByPolygonId={
                                updatePolygonByPolygonId
                              }
                              setDefaultGroupID={setDefaultGroupID}
                              defaultGroupID={defaultGroupID}
                              toggleDefaultGroup={toggleDefaultGroup}
                              showingGroups={showingGroups}
                              expandedGroups={expandedGroups}
                              toggleExpandedGroup={toggleExpandedGroup}
                              getItems={getItems}
                              row={item.row}
                              handlePolygonClick={handlePolygonClick}
                              selectedPolygonIndex={selectedPolygonIndex}
                              toggleGroupVisibility={toggleGroupVisibility}
                            />
                          );
                        }

                        return (
                          <SortableItem key={item.id} id={item.id}>
                            <Item
                              handlePolygonClick={handlePolygonClick}
                              selectedPolygonIndex={selectedPolygonIndex}
                              id={item.id}
                            />
                          </SortableItem>
                        );
                      })}
                    </div>
                  </SortableContext>
                  <DragOverlay>{getDragOverlay()}</DragOverlay>
                </DndContext>
              </>
            )}
          </Box>

        </FractionalBox>
        {/* </Grid> */}
      </Grid>

      <Modal open={feetModal} onClose={null}>
        <Paper
          style={{
            display: "flex",
            gap: 10,
            flexDirection: "column",
            alignItems: "center",
            width: 300,
            height: 500,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 20,
          }}
        >
          <Typography>
            This line lenght is {numberofPixels}px. Please write how many ft
            this pixel length corresponds to.
          </Typography>
          <TextField
            id="editGroupName"
            autoFocus
            fullWidth
            label="Feet"
            variant="outlined"
            value={numberofFeet}
            onChange={handleFeetChange}
          />

          <Button fullWidth onClick={factorCalculate}>
            Enter
          </Button>
          <Button fullWidth onClick={handleFeetModal}>
            Close
          </Button>
        </Paper>
      </Modal>
    </div>
  );

  function isContainer(id) {
    const item = data.items.find((item) => item.id === id);

    return !item ? false : item.container;
  }

  function isRow(id) {
    const item = data.items.find((item) => item.id === id);

    return !item ? false : item.row;
  }

  function getItems(parent) {
    return data.items.filter((item) => {
      if (!parent) {
        return !item.parent;
      }

      return item.parent === parent;
    });
  }
  function generateColor(data) {
    const existingColors = [
      "#ff0000", // red
      "#00ff00", // green
      "#ffff00", // yellow
      "#ff00ff", // magenta
      "#00ffff", // cyan
      "#ffa500", // orange
      "#800080", // purple
      "#008000", // dark green
      "#ff1493", // deep pink
    ];
    const itemsWithColor = data.items.filter(
      (item) => item.color !== undefined
    );
    if (itemsWithColor.length < 9) {
      return existingColors[itemsWithColor.length];
    } else {
      let red = Math.floor(Math.random() * 256); // random red value between 0 and 255
      let green = Math.floor(Math.random() * 256); // random green value between 0 and 255
      let blue = Math.floor(Math.random() * 256); // random blue value between 0 and 255

      // Convert decimal to hexadecimal
      let redHex = red.toString(16).padStart(2, "0"); // padStart ensures two digits
      let greenHex = green.toString(16).padStart(2, "0");
      let blueHex = blue.toString(16).padStart(2, "0");

      // Concatenate the RGB values to form a color code
      let randomColorString = `#${redHex}${greenHex}${blueHex}`;

      return randomColorString;
    }
  }
  function addItem(id, container, color, row) {
    setData((prev) => ({
      items: [
        {
          id: id ? String(id) : String(prev.items.length + 1),
          container,
          color,
          row,
        },
        ...prev.items,
      ],
    }));
    if (container) {
      setDefaultGroupID(id);
      setUpdateFlag(true);
    }
    setNewGroupName("");
  }

  function getItemIds(parent) {
    return getItems(parent).map((item) => item.id);
  }

  function findParent(id) {
    const item = data.items.find((item) => item.id === id);
    return !item ? false : item.parent;
  }

  function getDragOverlay() {
    if (!activeId) {
      return null;
    }

    if (isContainer(activeId)) {
      const item = data.items.find((i) => i.id === activeId);

      return (
        <Container row={item.row}>
          {getItems(activeId).map((item) => (
            <Item
              handlePolygonClick={handlePolygonClick}
              selectedPolygonIndex={selectedPolygonIndex}
              key={item.id}
              id={item.id}
            />
          ))}
        </Container>
      );
    }

    return <Item handlePolygonClick={handlePolygonClick} id={activeId} />;
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;
    setActiveId(id);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    let overId;
    if (over) {
      overId = over.id;
    }

    const overParent = findParent(overId);
    const overIsContainer = isContainer(overId);
    const activeIsContainer = isContainer(activeId);

    if (!overIsContainer) {
      //if the dragged item is not in a group
      return;
    }

    if (overIsContainer) {
      const overIsRow = isRow(overId);
      const activeIsRow = isRow(activeId);
      // only columns to be added to rows
      if (overIsRow) {
        if (activeIsRow) {
          return;
        }

        if (!activeIsContainer) {
          return;
        }
      } else if (activeIsContainer) {
        return;
      }
    }

    setData((prev) => {
      const activeIndex = data.items.findIndex((item) => item.id === id);
      const overIndex = data.items.findIndex((item) => item.id === overId);

      let newIndex = overIndex;
      const isBelowLastItem =
        over &&
        overIndex === prev.items.length - 1 &&
        draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;

      newIndex = overIndex >= 0 ? overIndex + modifier : prev.items.length + 1;

      let nextParent;
      if (overId) {
        nextParent = overIsContainer ? overId : overParent;
      }

      prev.items[activeIndex].parent = nextParent;
      const nextItems = arrayMove(prev.items, activeIndex, newIndex);

      return {
        items: nextItems,
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    let overId;
    if (over) {
      overId = over.id;
    }

    const activeIndex = data.items.findIndex((item) => item.id === id);
    const overIndex = data.items.findIndex((item) => item.id === overId);

    let newIndex = overIndex >= 0 ? overIndex : 0;

    if (activeIndex !== overIndex) {
      setData((prev) => ({
        items: arrayMove(prev.items, activeIndex, newIndex),
      }));
    }
    setUpdateFlag(true);
    setActiveId(null);
  }
});

export default Canvas;
