import React, { useRef,useEffect } from "react";
//rotas
//pages
import Slider from "./pages/Slider";
import { useSelector } from "react-redux";
import poster from "./assets/poster.png";

// import Home from "./pages/Home";
// import About from './pages/About';
// import SignIn from './pages/SignIn';
// import Contact from './pages/Contact';
//componentes
// import Navbar from "./components/Navbar";
import "./LandingPage.css";

function LandingPage() {
  const currentPdf = useSelector((state) => state.pdf.currentPdf);

  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5; // Set playback speed to 0.5 (half speed)
    }
  }, []);
  // const [visible, setVisible] = useState(true);
  // const [clickTitle, setclickTitle] = useState(false);
  // useEffect(() => {
  //   setVisible(true)
  //   const timer = setTimeout(() => {
  //     setVisible(false);
  //   }, 1000);

  //   // Cleanup the timer
  //   return () => clearTimeout(timer);
  // }, [clickTitle]);
  return (
    <>
     {/* {visible && <Navbar onClickTitle={() =>setVisible(false)}/>} backgroundColor: "#060606"*/}
      <div className="video-background" style={currentPdf && {background: "linear-gradient(299deg, rgb(0, 0, 0) 9%, rgb(0 0 35) 50%, rgb(0, 0, 0) 85%)"}}>
        <video
        style={currentPdf && {opacity: 0.3}}
          ref={videoRef}
          data-aid="HEADER_VIDEO"
          type="video/mp4"
          poster={poster}
          // poster="https://img1.wsimg.com/isteam/videos/uA41GmyyG8IMaxXdb"
          autoPlay
          loop
          muted
          playsInline
          src="https://websites.godaddy.com/categories/v4/videos/raw/video/uA41GmyyG8IMaxXdb"
        ></video>
      </div>
      <Slider />
    </>
  );
}

export default LandingPage;
