import { SET_AI_SCALE_DATA, SET_AI_ROOM_DATA, SET_CSV_DATA,RESET_REDUX } from "../actions/types";

export const initState = {
  scaleData: [],
  aiRooms: [],
  CSVData: []
};

const scaleRoomReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_AI_SCALE_DATA: {
      return { ...state, scaleData: action.scaleData };
    }

    case SET_AI_ROOM_DATA:
      return { ...state, aiRooms: action.aiRooms };

    case SET_CSV_DATA: {
      return { ...state, CSVData: action.CSVData };
    }
    case RESET_REDUX:
      return initState
    default:
      return state;
  }
};

export default scaleRoomReducer;
