export default [
    "#ffa500", // orange
    "Chartreuse",
    "Salmon",
    "Sienna",
    "Turquoise",
    "Crimson",
    "DarkKhaki",
    "Thistle",
    "DarkTurquoise",
    "DarkViolet",
    "FireBrick",
    "Fuchsia",
    "HotPink",
    "IndianRed",
    "LavenderBlush",
    "DarkRed",
    "LemonChiffon",
    "LightCoral",
    "LightPink",
    "LightSalmon",
    "LightYellow",
    "Linen",
    "MediumAquaMarine",
    "MediumOrchid",
    "MediumTurquoise",
    "MintCream",
    "MistyRose",
    "Moccasin",
    "Navy",
    "OldLace",
    "Olive",
    "Indigo",
    "Black",
    "OliveDrab",
    "Orange",
    "OrangeRed",
    "PaleTurquoise",
    "PaleVioletRed",
    "PapayaWhip",
    "PeachPuff",
    "Peru",
    "Plum",
    "Red",
    "RosyBrown",
    "#ff0000", // red
    "#00ff00", // green
    "#ffff00", // yellow
    "#ff00ff", // magenta
    "#00ffff", // cyan
    "#ffa500", // orange
    "#800080", // purple
    "#008000", // dark green
    "#ff1493"  // deep pink
  ]