import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // To use Redux state
import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Styled components for the table
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  border: 0.5px solid #7e7c7c99;
  padding: 6px;
  text-align: ${({ align }) => align || "left"};
  background-color: #1a1a1a;
  color: white;
  width: ${({ width }) => width || "auto"};
`;

const TableCell = styled.td`
  border: 0.5px solid #7e7c7c99;
  padding: 6px;
  text-align: ${({ align }) => align || "left"};
  color: white;
  width: ${({ width }) => width || "auto"};
`;

const LoadingRow = styled.tr`
  color: white;
  text-align: center;
`;

const StyledAccordion = styled(Accordion)`
  background-color: #282828;
  color: white;
  border: 1px solid #444;
  margin-bottom: 10px;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #333;
  font-size: 1.1em;
  color: white;
  min-height: 56px;
  padding-left: 3vh;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: #1a1a1a;
  color: white;
  padding: 0;
`;
const PageObjects = () => {
  const pdfId = useSelector((state) => state.pdf.currentPdfID);
  const page = useSelector((state) => state.pdf.currentPage);
  const [activeTab, setActiveTab] = useState("FR");
  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState({
  });
  const getBotResponse = async () => {
    setLoading(true); // Show typing indicator
    const objectsCopy = {
      FR: [{ name: "", quantity: 0, pricePerUnit: 0 }],
      FN: [{ name: "", quantity: 0, pricePerUnit: 0 }],
      FX: [{ name: "", quantity: 0, pricePerUnit: 0 }],
      EQ: [{ name: "", quantity: 0, pricePerUnit: 0 }],
    };
    try {
      const res = await fetch(
        "https://constra-api-srdsoovefq-uc.a.run.app/analyze-floor-plans",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pdf_id: pdfId, // Using the Redux state for pdfId
            page: page || 1, // Using the Redux state for the page number
            messages: [
              {
                role: "user",
                content:
                  "Analyze the following floor plan images and provide the following details:",
              },
              {
                role: "user",
                content:
                  "1. Separate the analysis by floors. For each floor, count the quantity of doors and windows, and provide door counts with dimensions. Be sure to identify any irregular doors, such as sliding doors. Example: 'First Floor - Interior doors: 8 - 3 feet 0 inches wide and 8 feet 0 inches tall.'",
              },
              {
                role: "user",
                content:
                  "2. Estimate the square footage of each room, and round the dimensions to the nearest 0.10 inch. Example: 'Master Bedroom: 17' 8.10\" x 12' 4.10\"'.",
              },
              {
                role: "user",
                content:
                  "3. Name the different materials called out on the exterior elevation.",
              },
              {
                role: "user",
                content: "4. Identify the used scale. Example: 'SCALE:'",
              },
              {
                role: "user",
                content:
                  "5. Identify the ELECTRICAL LEGEND and quantify the items shown in the legend, including ceiling fans, lights, and other fixtures. Provide a count of the electrical fixtures.",
              },
              {
                role: "user",
                content:
                  "6. Identify the number of rooms on each floor and provide their names.",
              },
              {
                role: "user",
                content:
                  "7. Assume or identify the ceiling height in each room, as this will be used for future volume calculations.",
              },
              { role: "user", content: "i need totel doors in a FR: Frames" },
              {
                role: "user",
                content: `i need like this formate data only no text only json data ${JSON.stringify(
                  objectsCopy
                )}`,
              },
            ],
          }),
        }
      );

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();

      // Clean the string to remove backticks and the "```json" part
      const cleanedData = data.analysis
        .replace(/```json/g, "") // Remove ```json
        .replace(/```/g, "") // Remove final ```
        .trim(); // Clean up any extra spaces

      // Parse the cleaned string into JSON
      const parsedAnalysis = JSON.parse(cleanedData);

      setObjects(parsedAnalysis); // Set the objects state with the parsed data
      console.log(parsedAnalysis, "parsedAnalysis");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Hide typing indicator after response
    }
  };

  useEffect(() => {
    if (pdfId) {
      getBotResponse();
    }
  }, [page, pdfId]);

  const fullNames = {
    FR: "Frames",
    FN: "Furnishings",
    FX: "Fixtures",
    EQ: "Equipment",
  };
  return (
    <>
      {/* Dropdown for selecting the tab */}
      {Object.keys(fullNames).map((tab) => (
        <StyledAccordion
          key={tab}
          expanded={activeTab === tab}
          onChange={() => setActiveTab(tab === activeTab ? "" : tab)}
        >
           
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}>
                
                <span
                style={{ backgroundColor: "white", color: "black" }}
                className="groupColor colorwithnumber"
              >
                {objects[tab] ? objects[tab].length: 0}
              </span>
            {fullNames[tab]}
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {loading ? (
              <tbody>
                <LoadingRow>
                  <td colSpan="3">Objects are loading...</td>
                </LoadingRow>
              </tbody>
            ) : (
              <Table>
                <thead>
                  <tr>
                    <TableHeader width="60%">Name</TableHeader>
                    <TableHeader align="right" width="20%">
                      Qty
                    </TableHeader>
                    <TableHeader align="right" width="20%">
                      Price
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {objects[tab] &&
                    objects[tab].map((item, index) => (
                      <tr key={index}>
                        <TableCell width="60%">{item.name}</TableCell>
                        <TableCell align="right" width="20%">
                          {item.quantity}
                        </TableCell>
                        <TableCell align="right" width="20%">
                          ${item.pricePerUnit}
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </>
  );
};

export default PageObjects;
