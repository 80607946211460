import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Divider,
  Box,
  Checkbox,
  FormControlLabel,
  Avatar,
} from "@material-ui/core";
import { setAuth } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { SiFacebook } from "react-icons/si";
import { IoArrowBackOutline } from "react-icons/io5";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { firebaseAuth } from "../../firebaseConfig";
import Profile from "./Profile";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    overflow: "hidden",
    boxShadow: "0 0 0.5vh 0.5vh #00000020",
    backdropFilter: "blur(0.5vh)",
    backgroundColor: "#34343485",
    padding: theme.spacing(4),
    maxWidth: 400,
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
  },
  title: {
    fontSize: "1.5rem",
    color: "white",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: "white",
    fontSize: "0.875rem",
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    backgroundColor: "white",
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    justifyContent: "center",
    position: "relative",
    "&.MuiButton-root:hover": {
      backgroundColor: "rgb(135 135 135)",
      color: "white",
    },
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  icon: {
    fontSize: "1.5rem",
  },
  text: {
    flex: 1,
    textAlign: "center",
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      color: "white",
      "& fieldset": {
        borderColor: "#695f5f",
      },
      "&:hover fieldset": {
        borderColor: "#695f5f",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#695f5f",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#695f5f",
      opacity: 1,
    },
  },
  divider: {
    width: "100%",
    backgroundColor: "#595959",
    margin: theme.spacing(2, 0),
  },
  orText: {
    margin: theme.spacing(0, 2),
    color: "white",
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  link: {
    color: "#5f8cf1",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  welcomeMessage: {
    fontSize: "1.25rem",
    fontWeight: 500,
    color: "green",
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  submitButton: {
    width: "100%",
    borderRadius: "1vh",
    cursor: "pointer",
    backgroundColor: "rgb(0 58 100 / 97%)",
    border: "0.2vh solid rgb(0 58 100 / 97%)",
    color: "#fffafa",
    "&:hover": {
      backgroundColor: "#4162abbf",
    },
    "&:focus": {
      backgroundColor: "#4162abbf",
    },
  },
  buttonContainedAI: {
    padding: "0 2vh",
    fontSize: "2.1vh",
    height: "5vh",
    width: "15vh",
    textTransform: "capitalize",
    borderRadius: "1vh",
    backgroundColor: "rgb(10 53 85 / 97%)",
    border: "0.2vh solid rgb(10 53 85 / 97%)",
    color: "#fffafa",
    "&:hover": {
      backgroundColor: "#4162abbf",
    },
    "&:focus": {
      backgroundColor: "#4162abbf",
    },
  },
  greenLink: {
    color: "green",
    textAlign: "center",
    fontSize: "1.7vh",
    display: "block",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  backIcon: {
    position: "absolute",
    top: "2.4pc",
    left: 6,
    cursor: "pointer",
    color: "white",
    fontSize: "1.5rem",
  },
  userAvatar: {
    width: 80,
    height: 80,
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));

const SignUpModal = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.auth);

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      dispatch(setAuth(user));
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      dispatch(setAuth(result.user));
    } catch (error) {
      console.error(error);
    }
  };

  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      dispatch(setAuth(result.user));
    } catch (error) {
      console.error(error);
    }
  };

  const handleAppleLogin = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      dispatch(setAuth(result.user));
    } catch (error) {
      console.error("Apple Sign-In error:", error);
console.error("Error code:", error.code);
console.error("Error message:", error.message);


    }
  };

  const handleLogout = async () => {
    try {
      await signOut(firebaseAuth);
      dispatch(setAuth(null));
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  const handleEmailSubmit = () => {
    if (validateEmail(email)) {
      setShowPasswordField(true);
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handlePasswordSubmit = async () => {
    if (validatePassword(password)) {
      try {
        // Try signing in the user
        const userCredential = await signInWithEmailAndPassword(
          firebaseAuth,
          email,
          password
        );
        dispatch(setAuth(userCredential.user));
        setEmail("");
        setPassword("");
      } catch (error) {
        console.log(error, "error", error.code);
        if (error.code === "auth/invalid-credential") {
          // If user not found, create a new account
          try {
            const userCredential = await createUserWithEmailAndPassword(
              firebaseAuth,
              email,
              password
            );
            dispatch(setAuth(userCredential.user));
            setEmail("");
            setPassword("");
          } catch (signupError) {
            console.error("Error signing up:", signupError);
            setPasswordError("Failed to create an account. Please try again.");
          }
        } else {
          console.error("Sign in error:", error);
          setPasswordError("Incorrect password or email.");
        }
      }
    } else {
      setPasswordError("Password must be at least 6 characters long.");
    }
  };
  const handleForgotPassword = async () => {
    if (validateEmail(email)) {
      try {
        await sendPasswordResetEmail(firebaseAuth, email);
        setMessage("Please check your email for password reset instructions.");
      } catch (error) {
        setMessage("Failed to send password reset email. Please try again.");
      }
    } else {
      setMessage("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <>
      {user ? (
        <Profile />
      ) : (
        <div
          className="body-main"
          id="Home"
          style={{ marginTop: 0, minHeight: "100vh", justifyContent: "center" }}
        >
          <Box className={classes.container}>
            <Typography className={classes.title}>
            {showPasswordField && (
              <IoArrowBackOutline
                className={classes.backIcon}
                onClick={() => {
                  setShowPasswordField(false);
                  setPassword("");
                  setPasswordError("");
                  setShowPassword(false);
                  setMessage("");
                }}
              />
            )}  Create an account or sign in to use constra.ai
            </Typography>
            {showPasswordField ? (
              <>
                {!message ? (
                  <>
                    <TextField
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      className={classes.textField}
                      style={{ marginBottom: 0, color: "white" }}
                      value={password}
                      onChange={handlePasswordChange}
                      error={!!passwordError}
                      helperText={passwordError}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showPassword}
                          onChange={() => setShowPassword(!showPassword)}
                          style={{ color: "white" }}
                        />
                      }
                      label="Show password"
                      style={{
                        color: "white",
                        width: "-webkit-fill-available",
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                      style={{ fontWeight: "bold" }}
                      onClick={handlePasswordSubmit}
                    >
                      Continue with Password
                    </Button>
                    <Button
                      onClick={handleForgotPassword}
                      className={classes.submitButton}
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        backgroundColor: "white",
                        marginTop: "2vh",
                      }}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Reset Password
                    </Button>
                  </>
                ) : (
                  <Typography className={classes.subtitle}>
                    {message}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <TextField
                  variant="outlined"
                  placeholder="Enter Email"
                  type="email"
                  className={classes.textField}
                  value={email}
                  style={{ color: "white" }}
                  required
                  onChange={handleEmailChange}
                  error={!!emailError}
                  helperText={emailError}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.submitButton}
                  style={{ fontWeight: "bold" }}
                  onClick={handleEmailSubmit}
                >
                  Continue with Email
                </Button>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 16,
                  }}
                >
                  <Divider className={classes.divider} />
                  <Typography className={classes.orText}>or</Typography>
                  <Divider className={classes.divider} />
                </div>

                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleGoogleLogin}
                >
                  <div className={classes.buttonContent}>
                    <FcGoogle className={classes.icon} />
                    <Typography
                      className={classes.text}
                      style={{ fontWeight: "bold" }}
                    >
                      Continue with Google
                    </Typography>
                  </div>
                </Button>

                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleFacebookLogin}
                >
                  <div className={classes.buttonContent}>
                    <SiFacebook
                      className={classes.icon}
                      style={{ color: "blue" }}
                    />
                    <Typography
                      className={classes.text}
                      style={{ fontWeight: "bold" }}
                    >
                      Continue with Facebook
                    </Typography>
                  </div>
                </Button>

                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleAppleLogin}
                >
                  <div className={classes.buttonContent}>
                    <FaApple
                      className={classes.icon}
                      style={{ color: "black" }}
                    />
                    <Typography
                      className={classes.text}
                      style={{ fontWeight: "bold" }}
                    >
                      Continue with Apple
                    </Typography>
                  </div>
                </Button>              </>
            )}
          </Box>
        </div>
      )}
    </>
  );
};

export default SignUpModal;
