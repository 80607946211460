import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { RingLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { Document, pdfjs } from "react-pdf";
import { useLoaderData, useNavigate } from "react-router-dom";
import { PdfPageCard } from "../components/PdfPageCard";
import { setScaleData, setAiRooms } from "../actions/scaleAndRoomActions";
import { reSetRedux } from "../actions/polygonActions";
import { toast } from "react-toastify";

import {
  setBackgroundLayout,
  setBackgroundLayoutLoading,
  togglePDF,
  setCurrentPdfFile,
  setCurrentPage,
  setCurrentPdfID,
  setCurrentPdfPath,
  setBlobPDFlocal,
  setFirstUpload,
} from "../actions/pdfActions";
// import { setTool } from "../actions/toolActions";
import PDFModel from "./PDFModel";
import axios from "axios";
import ErrorPage from "../landingPage/pages/ErrorPage";
import { ScaleLoader } from "react-spinners";
import { PdfPageImageCard } from "../components/PdfPageImageCard";
import { useParams } from "react-router-dom";

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
};

const loadingBarStyle = {
  position: "fixed",
  top: "39%",
  left: "48.5%",
  zIndex: 10000,
};
let cancelTokenSource = null;
const PdfViewer = forwardRef(({ onChangeFile }, ref) => {
  const fileInputRef = React.useRef();
  const params = useParams();
  const cancelTokenSource1 = React.useRef(null);
  const toastId = React.useRef(null);

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    triggerFileInputClick: () => {
      fileInputRef.current.click();
    },
    onFileUploadChange,
  }));
  const pdfID = useSelector((state) => state.pdf.currentPdfID);
  const scaleData = useSelector((state) => state.pdf.scaleData);
  const isBackgroundLoading = useSelector(
    (state) => state.pdf.setBackgroundLayoutLoading
  );

  const pdfData = useLoaderData();
  const [pdfFile, setPdfFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState([]);
  const [selectedPageIndex, setSelectedPageIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // togglePDF(true);

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  useEffect(() => {
    const getPdfRefresh = async () => {
      try {
        dispatch(togglePDF(true));
        cancelTokenSource1.current = axios.CancelToken.source();
        toastId.current = toast.loading("Loading... 0%");
        const response = await axios.get(
          `https://constra-api-srdsoovefq-uc.a.run.app/pdfs/base64/${pdfData.id}`,
          {
            cancelToken: cancelTokenSource1.current.token,
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.total;
              const current = progressEvent.loaded;
              const percentCompleted = Math.round((current / total) * 100);
              toast.update(toastId.current, {
                render: `Loading... ${percentCompleted}%`,
                type: toast.TYPE.INFO,
              });
            },
          }
        );

        if (response?.data?.base64) {
          const blob = base64ToBlob(response?.data?.base64);
          const url = URL.createObjectURL(blob);
          setPdfFile(url);
          dispatch(setBlobPDFlocal(url));
          toast.update(toastId.current, {
            render: `Loading...`,
            type: toast.TYPE.INFO,
          });
          setTimeout(() => {
            toast.dismiss(toastId.current);
          }, 5000);

          // Cleanup the URL object when the component unmounts
          return () => URL.revokeObjectURL(url);
        }
      } catch (error) {
        toast.dismiss();
        console.error("An error has occurred:", error);
      }
    };
    !pdfFile && params.pdfID && getPdfRefresh();
  }, []);
  const base64ToBlob = (base64) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "application/pdf" });
  };

  useEffect(() => {
    if (pdfData) {
      dispatch(setCurrentPdfPath(JSON.stringify(pdfData)));
      dispatch(setCurrentPdfFile(JSON.stringify(pdfData.path)));
      dispatch(setCurrentPdfID(pdfData.id));
      setLoading(false);
    }
  }, [pdfData]);

  const resetFileInput = () => {
    cancelTokenSource1?.current &&
      cancelTokenSource1.current.cancel("unmounted");
    dispatch(reSetRedux());
    setSelectedPageIndex(null);
    navigate("/", { replace: true });
    setPdfFile(null);
    onChangeFile(true);
  };

  const onFileUploadChange = async (event) => {
    resetFileInput();
    let fileData = null;

    if (event.size) {
      fileData = event;
    } else {
      if (!event?.target?.files?.length) return;
      const [file] = event?.target?.files;
      fileData = file;
    }
    if (fileData) {
      const sizeLimit = 200 * 1024 * 1024; // 5 MB size limit
      if (fileData.size > sizeLimit) {
        toast.error("File size exceeds 200 MB.");
        return;
      }
      cancelTokenSource && cancelTokenSource.abort();
      cancelTokenSource = new AbortController();

      const formData = new FormData();
      formData.append("file", fileData);
      setLoading(true);
      const url = URL.createObjectURL(fileData);
      setPdfFile(url);
      dispatch(setBlobPDFlocal(url));
      dispatch(togglePDF(true));
      setLoading(false);

      try {
        toastId.current = toast.loading(
          "Please wait, we are uploading pdf file. 0%"
        );
        const pdfUploadResponse = await axios.post(
          "https://constra-api-srdsoovefq-uc.a.run.app/pdfs",
          formData,
          {
            signal: cancelTokenSource.signal,
            headers: {
              "Content-Type": "multipart/form-data",
            },

            onUploadProgress: (progressEvent) => {
              const total = progressEvent.total;
              const current = progressEvent.loaded;
              const percentCompleted = Math.round((current / total) * 100);
              toast.update(toastId.current, {
                render: `Please wait, we are uploading pdf file. ${percentCompleted}%`,
                type: toast.TYPE.INFO,
              });
            },
          }
        );
        toast.update(toastId.current, {
          render: `Loading...`,
          type: toast.TYPE.INFO,
        });
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 5000);
        dispatch(setCurrentPdfFile(pdfUploadResponse.data.path));
        dispatch(setCurrentPdfID(pdfUploadResponse.data.id));
        navigate(`/${pdfUploadResponse.data.id}`, { replace: true });
        dispatch(setFirstUpload(true));

        // const url = URL.createObjectURL(file);
        // setPdfFile(url);
        // dispatch(setBlobPDFlocal(url));
        // dispatch(togglePDF(true));

        // setLoading(false);
      } catch (error) {
        toast.dismiss();
        error.code !== "ERR_CANCELED" &&
          toast.error("There is some issue please try again latter.", {
            autoClose: 10000,
          });
        console.error(error);
        error.code !== "ERR_CANCELED" && navigate("/500", { replace: true });
      }
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(
      Array(numPages)
        .fill(null)
        .map((_, i) => i + 1)
    );
  };

  // const onPageClick = (pageNum) => {

  //   dispatch(setBackgroundLayoutLoading(true));
  //   extractImagesFromPDF2(pdfFile, pageNum).then((image) => {
  //     dispatch(setBackgroundLayout(image));
  //     setSelectedPageIndex(pageNum);
  //     dispatch(setCurrentPage(pageNum));
  //     dispatch(setBackgroundLayoutLoading(false));
  //   });
  // };

  // const onImagePageClick = (pageNum, image) => {
  //   if (selectedPageIndex !== pageNum) {

  //     setSelectedPageIndex(pageNum);
  //     dispatch(setCurrentPage(pageNum));
  //     dispatch(setBackgroundLayoutLoading(true));
  //     dispatch(setBackgroundLayout(image));
  //     dispatch(setBackgroundLayoutLoading(false));
  //     pdfFile && onPageClick(pageNum)
  //   }
  //   // if (!scaleData && pdfData.ai_response) {
  //   //   dispatch(setScaleData(JSON.parse(pdfData.ai_response)));
  //   // }
  // };
  // useEffect(() => {

  //   async function processPDF(pdfFile, pageCount) {
  //     if (pdfID) {
  //       try {
  //         const payload = {
  //           id: pdfID,
  //           images: [],
  //         };

  //         await axios.put(
  //           "https://constra-api-srdsoovefq-uc.a.run.app/pdfs/update",
  //           payload
  //         );
  //         navigate(`/${pdfID}`, { replace: true });
  //       } catch (error) {
  //         console.error("An error has occurred:", error);
  //         return <ErrorPage status={500} />;
  //       }
  //     }
  //     if (pageCount.length > 0 && pageCount.length < 9 && pdfID) {
  //       let base64ImagesArray = [];

  //       for (let i = 1; i <= pageCount.length; i++) {
  //         let image = await extractImagesFromPDF(pdfFile, i);
  //         base64ImagesArray.push({ page: i, image: image });
  //       }

  //       try {
  //         const payload = {
  //           id: pdfID,
  //           images: base64ImagesArray,
  //         };

  //         await axios.put(
  //           "https://constra-api-srdsoovefq-uc.a.run.app/pdfs/update",
  //           payload
  //         );
  //         navigate(`/${pdfID}`, { replace: true });
  //       } catch (error) {
  //         console.error("An error has occurred:", error);
  //         return <ErrorPage status={500} />;
  //       }
  //     }
  //   }
  //   processPDF(pdfFile, pageCount);
  // }, [pageCount, pdfID]);

  return (
    <>
      {/* Loading overlay */}
      {loading && (
        <div style={overlayStyle}>
          <div className="loading-bar" style={loadingBarStyle}>
            <ScaleLoader
              color="#56586C"
              loading={loading}
              size={200}
              aria-label="Loading Spinner"
            />
          </div>
        </div>
      )}
      {pdfFile && (
        <div style={{ display: "none" }}>
          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
          ></Document>
        </div>
      )}
      {/* <PDFModel> 
        <>
          {pdfFile ? (
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              {pageCount.map((page) => (
                <PdfPageCard
                  loading={page === selectedPageIndex && isBackgroundLoading}
                  key={page}
                  page={page}
                  onClick={onPageClick}
                />
              ))}
            </Document>
          ) : pdfData && pdfData.images ? (
            <div className="react-pdf__Document2">
              {pdfData.images.map((image) => (
                <PdfPageImageCard
                  key={image.page}
                  page={image.page}
                  image={image.image}
                  onClick={onImagePageClick}
                />
              ))}
            </div>
          ) : (
            <div className="react-pdf__Document3">
              <RingLoader
                color="white"
                loading={true}
                size={50}
                aria-label="Loading Spinner"
              />
            </div>
          )}
        </>
      </PDFModel> */}
      <input
        type="file"
        accept="application/pdf" // Only allow PDF files
        style={{ display: "none" }}
        onChange={onFileUploadChange.bind(this)}
        ref={fileInputRef}
        onClick={(event) => {
          if (pdfData) {
            event.target.value = null;
          }
        }} // We clear input on every load
      />
      {/* <div
        className={`pdf-viewer shadowsColumn2 ${isOpen ? "shown" : "hidden"}`}
      >
        <div className="pdf-viewer-content">
          <Button
            fullWidth
            component="label"
            className="upload-file-button"
            variant="contained"
            startIcon={<i className="fa fa-file-upload"></i>}
            onClick={() => fileInputRef.current.click()}
          >
            Upload PDF file
            <input
              type="file"
              style={{ display: "none" }}
              onChange={onFileUploadChange.bind(this)}
              ref={fileInputRef}
              onClick={(event) => {
                event.target.value = null;
              }} // We clear input on every load
            />
          </Button>

          {pdfFile ? (
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              {pageCount.map((page) => (
                <PdfPageCard
                  loading={page === selectedPageIndex && isBackgroundLoading}
                  key={page}
                  page={page}
                  onClick={onPageClick}
                />
              ))}
            </Document>
          ) : null}

          {pdfData && (
            <PdfPageImageCard
              page={1}
              onClick={onImagePageClick}
              image={pdfData.drawings.page1.image}
            />
          )}
        </div>
      </div> */}
    </>
  );
});

async function extractImagesFromPDF(pdfUrl, pageNum) {
  const pdf = await pdfjs.getDocument(pdfUrl).promise;

  const page = await pdf.getPage(pageNum);
  const gridContainer = document.getElementById("gridContainer");
  const originalViewport = page.getViewport({ scale: 1 });
  const scale = Math.min(
    gridContainer?.clientWidth / originalViewport?.width,
    gridContainer?.clientHeight / originalViewport?.height
  );
  const viewport = page.getViewport({ scale });

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const imageWidth = viewport.width;
  const imageHeight = viewport.height;

  canvas.height = imageHeight;
  canvas.width = imageWidth;

  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };

  await page.render(renderContext).promise;

  return canvas.toDataURL("image/png");
}
export { PdfViewer };
