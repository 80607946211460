import { combineReducers } from "redux";

import toolReducer from "./toolReducer";
import authReducer from "./authReducer";
import cursorReducer from "./cursorReducer";
import sheetReducer from "./sheetReducer";
import pdfReducer from "./pdfReducer";
import polygonReducer from "./polygonReducer";
import scaleRoomReducer from "./scaleAndRoomReducer";
import chatReducer from "./chatAIReducer";

export default combineReducers({
  tool: toolReducer,
  cursor: cursorReducer,
  sheet: sheetReducer,
  pdf: pdfReducer,
  polygon: polygonReducer,
  aiScaleRooms: scaleRoomReducer,
  auth:authReducer,
  AIChat:chatReducer
});
