import {
  SET_BACKGROUND_LAYOUT,
  SET_BACKGROUND_LAYOUT_LOADING,
  TOGGLE_PDF,
  SET_CURRENT_PDF_FİLE,
  SET_CURRENT_PAGE,
  SET_CURRENT_PDF_ID,
  SET_SCALE_DATA,
  SET_CURRENT_PDF_PATH,
  SET_CURRENT_PDF_FILE_LOCALL,
  SET_REFRESH_PDF_DATA_FLAG,
  SET_FIRST_UPLOAD,
} from "./types";

export const setBlobPDFlocal = (blb) => {
  return {
    type: SET_CURRENT_PDF_FILE_LOCALL,
    blobPDFlocal: blb,
  };
};

export const togglePDF = (show) => {
  return {
    type: TOGGLE_PDF,
    show: show,
  };
};
export const setCurrentPdfFile = (pdf) => {
  return {
    type: SET_CURRENT_PDF_FİLE,
    pdf: pdf,
  };
};

export const setCurrentPdfPath = (path) => {
  return {
    type: SET_CURRENT_PDF_PATH,
    path: path,
  };
};

export const setCurrentPdfID = (id) => {
  return {
    type: SET_CURRENT_PDF_ID,
    id: id,
  };
};

export const setRefreshPdfDataFlag = (flag) => {
  return {
    type: SET_REFRESH_PDF_DATA_FLAG,
    refreshFlag: flag,
  };
};

export const setScaleData = (data) => {
  return {
    type: SET_SCALE_DATA,
    data: data,
  };
};

export const setCurrentPage = (page) => {
  return {
    type: SET_CURRENT_PAGE,
    page: page,
  };
};

export const setBackgroundLayout = (image) => {
  return {
    type: SET_BACKGROUND_LAYOUT,
    background: image,
  };
};
export const setBackgroundLayoutLoading = (status) => {
  return {
    type: SET_BACKGROUND_LAYOUT_LOADING,
    status: status,
  };
};

export const setFirstUpload = (firstUpload) => {
  return {
    type: SET_FIRST_UPLOAD,
    firstUpload: firstUpload,
  };
};
