import { SET_AUTH_DATA } from '../actions/types';

const initState = {
  auth: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return {
        ...state,
        auth: action.payload
      }
    default:
      return state;
  }
}

export default authReducer;