import React, { useState } from "react";
import {
  Modal,
  LinearProgress,
  Typography,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { RiCloseFill } from "react-icons/ri";

import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import BounceLoader from "react-spinners/BounceLoader";
import axios from "axios";
import { toast } from 'react-toastify';

const overlayStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const loadingBarStyle = {
  backgroundColor: "rgb(2 31 53 / 97%)",
  color: "#fff",
  padding: "3vh",
  paddingTop: 0,
  borderRadius: "1vh",
  boxShadow: "0px 0.5vh 1vh rgba(0, 0, 0, 0.2)",
  textAlign: "center",
};

const loadingBarStylebg = {
  backgroundColor: "rgb(2 31 53 / 97%)",
  borderRadius: "1vh",
  width: "54vh",
  boxShadow: "0px 0.5vh 1vh rgba(0, 0, 0, 0.2)",
};

const iconStyle = {
  fontSize: "3.5vh",
  cursor: "pointer",
  color: "#fff",
};

const buttonStyle = {
  width: "100%",
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
    width: '50%',
    margin: 'auto',
  },
  formCard: {
    marginBottom: theme.spacing(2),
    width: '95%',
  },
  formInput: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: '1px solid #ccc',
  },
  formTextarea: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: '1px solid #ccc',
  },
  errorText: {
    color: 'red',
    fontSize: '0.875rem',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  submitButton: {
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    backgroundColor: "rgb(0 58 100 / 97%)",
    border: '0.2vh solid rgb(0 58 100 / 97%)', // Converted from 2px
    color: "#fffafa",
    "&:hover": {
      backgroundColor: "#4162abbf", // Adjust the hover background color here
    },
    "&:focus": {
      backgroundColor: "#4162abbf", // Adjust the focus background color here
    },
  },
  feedbackButton: {
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    backgroundColor: "#ffffff",
    border: '0.2vh solid #ffffff', // Converted from 2px
    color: "#000",
    "&:hover": {
      backgroundColor: "#adb2bbbf", // Adjust the hover background color here
    },
    "&:focus": {
      backgroundColor: "#adb2bbbf", // Adjust the focus background color here
    },
  },
}));

const LoadingScreen = ({ onClose, isScaleLoading, error }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(100);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    feedback: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formValues.fullName) {
      errors.fullName = "Full Name is required";
    }
    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formValues.feedback) {
      errors.feedback = "Feedback is required";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post('https://constra-api-srdsoovefq-uc.a.run.app/contact-us', {
          name: formValues.fullName,
          email: formValues.email,
          feedBack: formValues.feedback.concat(' ', `pdfID: ${error.pdfID}  page: ${error.currentPage}` )
        });
        toast.success(response.data.message);
        setFormValues({
          fullName: "",
          email: "",
          feedback: ""
        });
        setFormErrors({});
        handleOkClick()
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleOkClick = () => {
    onClose(false);
    setShowFeedbackForm(false);
  };

  const handleGiveFeedbackClick = () => {
    setShowFeedbackForm(true);
  };

  return (
    <Modal
      open={isScaleLoading && error.errorAiRoom !== 'canceled'}
      onClose={() => {
        handleOkClick()
      }}
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal-description"
      style={overlayStyle}
    >
      <div style={loadingBarStylebg}>
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start"
        }}>
          <RiCloseFill
            style={iconStyle}
            onClick={() => {
              handleOkClick()
            }}
          />
        </div>
          <div style={loadingBarStyle}>
            <Typography
              variant="body1"
              style={{
                marginBottom: "1vh",
                fontSize: "2vh",
                textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
              }}
            >
              Processing Constra Model 1.0...
            </Typography>
            <div style={{ display: "grid", justifyContent: "center" }}>
              {error.errorScale || error.errorAiRoom ? (
                <ErrorOutlineOutlinedIcon style={{ fontSize: "8vh", color: "rgb(178 24 24)" }} />
              ) : (
                <DoneAllIcon style={{ fontSize: "8vh", color: "green" }} />
              )}
            </div>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li
                style={{
                  textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "2vh",
                  alignItems: "center",
                  marginBottom: 2
                }}
              >
                
                {error.errorScale ? (
                 <>Scale recognition failed</>
                ) : (
                 <>Scale recognition successful</>
                )}
              </li>
              <li
                style={{
                  textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "2vh",
                  alignItems: "center",
                }}
              >
              
                {error.errorAiRoom ? (
                  <>AI Room recognition failed</>
                ) : (
                 <>AI Room recognition successful</>
                )}
              </li>
            </ul>
            {!showFeedbackForm ? (
            <>
            <LinearProgress variant="determinate" value={progress} />
            <Typography
              variant="body1"
              style={{
                marginTop: "2vh",
                fontSize: "2vh",
                textShadow: "0.1vh 0.1vh 0.2vh rgba(0,0,0,0.5)",
              }}
            >
              {error.errorScale ?  "The model could not find dimensions. Please upload a residential plan with dimensions clearly identified.":"Thanks for waiting, model complete, have fun!"}
            </Typography>
          {(error.errorScale || error.errorAiRoom) &&  <div style={{ marginTop: "2vh" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button variant="contained" className={classes.submitButton} color="primary" onClick={handleOkClick} style={buttonStyle}>
                    Ok
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" className={classes.feedbackButton}  color="secondary" onClick={handleGiveFeedbackClick} style={buttonStyle}>
                    Give Feedback
                  </Button>
                </Grid>
              </Grid>
            </div>}
            </>
          ) : (
          <form style={{ paddingTop: "3vh"}} onSubmit={handleSubmit}>
            <div className={classes.formCard}>
              <input
                className={classes.formInput}
                type="text"
                name="fullName"
                placeholder="Full Name"
                required
                value={formValues.fullName}
                onChange={handleChange}
              />
              {formErrors.fullName && (
                <Typography className={classes.errorText}>
                  {formErrors.fullName}
                </Typography>
              )}
            </div>
            <div className={classes.formCard}>
              <input
                className={classes.formInput}
                type="email"
                name="email"
                placeholder="Email"
                required
                value={formValues.email}
                onChange={handleChange}
              />
              {formErrors.email && (
                <Typography className={classes.errorText}>
                  {formErrors.email}
                </Typography>
              )}
            </div>
            <div className={classes.formCard}>
              <textarea
                className={classes.formTextarea}
                maxLength="420"
                rows="3"
                name="feedback"
                placeholder="Feedback"
                required
                value={formValues.feedback}
                onChange={handleChange}
              />
              {formErrors.feedback && (
                <Typography className={classes.errorText}>
                  {formErrors.feedback}
                </Typography>
              )}
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" className={classes.submitButton}  style={buttonStyle} type="submit" disabled={loading}>
                  {loading ? (
                  <BounceLoader
                    color="#56586C"
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                  />
                ) : (
                  "Submit"
                )}
                  </Button>
                </Grid>
              </Grid>
          </form>
        )}
          </div>
       
      </div>
    </Modal>
  );
};

export default LoadingScreen;
