import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import {
    togglePDF,
  } from "../actions/pdfActions";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'grid',
    opacity: 1,
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
   
    justifyItems: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  backdropRoot: {
    // Add your custom styles for .MuiBackdrop-root here
    // For example:
    backgroundColor: 'transparent', // Semi-transparent black background
  },
  paper: {
    position: "absolute",
    bottom: "23.9%",
    borderRadius:0,
    // border: '2px solid #000',
    backgroundColor: "#002039",
    width: "43.5%"
  },
}));

const PDFModel = ({ children }) => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.pdf.isOpen);

  const classes = useStyles();

  const handleClose = () => {
    dispatch(togglePDF(false));
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        // closeAfterTransition
        BackdropProps={{
          // timeout: 500,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)', // Semi-transparent black background
            // Add any other styles you want to apply to the backdrop here
          }
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default PDFModel;