import React, { useEffect, useRef, useState } from "react";
import SignUp from "../components/SignUp";
import axios from "axios";
import { PdfViewer } from "../../layout/PdfViewer";
import { Grid, Typography } from "@material-ui/core";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Canvas from "../../containers/Canvas";
import LoadingScreen from "../components/LoadingScreen";
import { useNavigate } from "react-router-dom";
import CommonDialog from './../components/CommonDialog';
import GroupButton from './../components/GroupButton';
import { styled } from "@material-ui/core/styles";
import { ImUpload } from "react-icons/im";
import { IoIosChatboxes } from "react-icons/io";
import { MdOutlineMapsHomeWork } from "react-icons/md";

import { TbRulerMeasure } from "react-icons/tb";

const FractionalBox = styled(Box)(({ fraction }) => ({
  flexBasis: `calc(${fraction} * 100% / 12)`,
}));
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "end",
    margin: "1.2vh",//5
    width: "30%",
    justifyContent: "center"
  },
  buttonContainer10: {
    marginTop: "2%",
    marginBottom: 4,
  },
  buttonContainer1: {
    width: "67%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginTop: "10vh",
  },
  Container100: {
    width: "100%",
  },
  CenterButtons:{
    position:"absolute",
    top: "30%",
    zIndex:999,
    height: "6vh !important",
    width: '23vh !important'
  },
  CenterButtons2:{
    position:"absolute",
    top: "38%",
    width: '23vh !important',
    height: "6vh !important",
    zIndex:999

  },
  buttonContained: {
    marginRight: '2vh', // theme.spacing(2)
    padding: '0 2vh', // theme.spacing(0, 2)
    fontSize: '2.1vh', // Adjusted from 0.9rem, assuming 1rem ≈ 2vh
    height: '7vh', // Converted from 50px
    width: '23vh', // Assuming '10pc' as 10% of height
    textTransform: "capitalize",
    borderRadius: '1vh', // Converted from 10px
    borderColor: "#e5e5e5",
    color: "black",
    backgroundColor: "#e5e5e5", // Adjust the background color here
    "&:hover": {
      backgroundColor: "#fffafa", // Adjust the hover background color here
    },
    "&:focus": {
      backgroundColor: "#fffafa", // Adjust the focus background color here
    },
  },
  buttonContainedAI: {
    padding: '0 2vh', // theme.spacing(0, 2)
    fontSize: '2.1vh', // Adjusted from 0.9rem, assuming 1rem ≈ 2vh
    height: '7vh', // Converted from 50px
    width: '23vh', // Assuming '10pc' as 10% of height
    textTransform: "capitalize",
    borderRadius: '1vh', // Converted from 10px
    backgroundColor: "rgb(2 31 53 / 97%)",
    border: '0.2vh solid rgb(2 31 53 / 97%)', // Converted from 2px
    color: "#fffafa",
    "&:hover": {
      backgroundColor: "#4162abbf", // Adjust the hover background color here
    },
    "&:focus": {
      backgroundColor: "#4162abbf", // Adjust the focus background color here
    },
  },
  buttonOutlined: {
    padding: '0 2vh', // theme.spacing(0, 2)
    fontSize: '2.1vh', // Adjusted from 0.9rem, assuming 1rem ≈ 2vh
    height: '7vh', // Converted from 50px
    width: '23vh', // Assuming '10pc' as 10% of height
    textTransform: "capitalize",
    borderRadius: '1vh', // Converted from 10px
    color: "#666666",
    cursor: "not-allowed",
    pointerEvents: "none",
    backgroundColor: "#827e7e",
    borderColor: "#696868",
    "&:hover": {
      color: "#666666",
      cursor: "not-allowed",
      pointerEvents: "none",
    },
    "&:focus": {
      color: "#666666",
      cursor: "not-allowed",
      pointerEvents: "none",
    },
  },
}));

const Home = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [zoomScreen, setZoomScreen] = useState(true);
  const [ischatOpen, setChatopen] = useState(1);
  const dropZoneRef = useRef(null);
  const messagesAILength = useSelector((state) => state.AIChat.messages.length);
console.log(messagesAILength,"messagesAI")
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveDialog = () => {
    // Handle save action here
    setDialogOpen(false);
    navigate("/", { replace: true });
    window.location.reload();
  };
  const [isLoading, setLoading] = useState(false);
  const signupSubmit = async (email) => {
    try {
      setLoading(true)
      const response = await axios.post(
        "https://constra-api-srdsoovefq-uc.a.run.app/emails",
        {
          email: email,
        }
      );
      console.log(response);
      setLoading(false)
      toast.success("Email saved successfully");
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };
  const classes = useStyles();
  const currentPdf = useSelector((state) => state.pdf.currentPdf);
  const pdfId = useSelector((state) => state.pdf.currentPdfID);
  const blobPDFlocal = useSelector((state) => state.pdf.blobPDFlocal);
  const [isScaleLoading, setScaleLoading] = useState(true);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const currentPage = useSelector((state) => state.pdf.currentPage);

  const pdfViewerRef = useRef();
  const canvasRef = useRef();
  const handleButtonClick = () => {
    if (pdfViewerRef.current) {
      pdfViewerRef.current.triggerFileInputClick();
    }
  };
  const onClickAI = () => {
    if (!currentPdf) {
      toast.warn("Please wait for pdf upload.")
      return
    }
    console.log(canvasRef, "canvasRef");
    if (canvasRef.current) {
      canvasRef.current.getAIResponseSelectedPage();
      setScaleLoading(false);
      setIsAiLoading(true)
    }
  };
 
  useEffect(() => {
    if (pdfId &&currentPage) {
    onClickAI();
    }
    // setScaleLoading(true);
    // setIsAiLoading(false)
  }, [currentPage,pdfId]);
  const toastId = useRef(null);
  const handleDrop = (event) => {
    event.preventDefault();
    dropZoneRef?.current && dropZoneRef.current.classList.remove('drop-zone--over');
    const file = event.dataTransfer.files[0];
    if (pdfViewerRef.current) {
      pdfViewerRef.current.onFileUploadChange(file);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    dropZoneRef?.current && dropZoneRef.current.classList.add('drop-zone--over');
  };

  const handleDragLeave = () => {
    dropZoneRef?.current && dropZoneRef.current.classList.remove('drop-zone--over');
  };
  const Before = () => {
    return (
      <div className="body-main" id="Home">
        <Grid container className={classes.buttonContainer1}>
            <FractionalBox
          id="Tools"
          fraction={2.3}
          className="shadowsColumn containerColl grid-center-main"
        >
            <span className="modelTitle">
              TOOLS
            </span>
          </FractionalBox>
          <FractionalBox
          id="Tools"
          fraction={6.5}
          ref={dropZoneRef}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          className="shadowsColumn containerColl grid-center-main"
        >
            <span
              className="modelTitle"
              style={{
                textAlign: "center"
              }}
            >
              MODEL
            </span>

            <span className="loadingPerview">
              <span
                className="modelTitle"

                style={{
                  color: "white",
                  opacity: 1,
                  fontSize: '2.3vh',
                  textAlign: "center",
                  fontWeight: 'normal'
                }}
              >
                Upload a residential floor plan PDF and save hours measuring and estimating with our model.
              </span>
            </span>
          </FractionalBox>
            <FractionalBox
          id="Tools"
          fraction={2.3}
          className="shadowsColumn containerColl grid-center-main"
        >
            <span  className="modelTitle">
              MEASUREMENTS
            </span>
          </FractionalBox>
        </Grid>
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.buttonContained}
            onClick={handleButtonClick}
          >
            Upload PDF
          </Button>
          <Button variant="contained" className={classes.buttonOutlined}>
            Run AI
          </Button>
        </Box>
      </div>
    );
  };
  return (
    <>
      <PdfViewer ref={pdfViewerRef} onChangeFile={() => {
        if (canvasRef?.current?.cancelTokenSource) {
          canvasRef.current.cancelTokenSource.abort()
        }
        setScaleLoading(true)
        toast.dismiss();
        setIsAiLoading(false)
      }} />
      <CommonDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        handleSave={handleSaveDialog}
        headerText="Upload New PDF"
        bodyText="Are you sure you want to upload a new PDF? It will remove the older data or save the URL for future use."
      />
      {(currentPdf || blobPDFlocal) ?
        <div className="body-main" id="Home">
          <LoadingScreen
          start={isScaleLoading || isAiLoading}
            isScaleLoading={isAiLoading}
            onClose={() => {
              toastId.current = toast.loading("Processing Constra Model 1.0...", {
                onClick: () => {
                  toast.dismiss(toast.dismiss(toastId))
                  setIsAiLoading(true)
                }
              });
              setIsAiLoading(false)
            }}
          />
          <div
            id="gridContainer"
            style={{ background: "transparent", width: zoomScreen ? "80%": "96%" }}
            ref={dropZoneRef}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <Canvas ref={canvasRef} 
            onClickZoomScreen={() =>{setZoomScreen(!zoomScreen)}}
            zoomScreen={zoomScreen}
            uploadPdfButtonClick={() =>{handleButtonClick()}}
            ischatOpen={ischatOpen}
            hideRunAILoading={(f) => {
              console.log(f.code, "f")
              toastId.current && toast.dismiss(toastId.current);
              f.code !== "ERR_CANCELED" && setIsAiLoading(false)
              setScaleLoading(false);
            }} />
          </div>
          <Box className={classes.buttonContainer} style={{ height: "9.5vh",margin: 0,  width: zoomScreen ? "80%": "96%", justifyContent:"center", alignItems:"center"}}>
            {/* <Button
              variant="contained"
              style={{marginRight: 0, width:"21%"}}
              className={`${classes.buttonContained} ${(messagesAILength ===0 && ischatOpen ===1) && classes.CenterButtons}`}
              onClick={handleButtonClick}
              // onClick={handleOpenDialog}
            >
                    <span style={{paddingRight: 7, display: 'flex'}}>

<ImUpload />
</span>
            Upload PDF 
            </Button> */}
            <GroupButton             ischatOpen={ischatOpen} setChatopen={(isChat) =>{setChatopen(isChat)}}/>
            {/* <Button
              variant="contained"
              onClick={onClickAI}
              style={{ width:"21%"}}
              className={`${(isScaleLoading && currentPage > 0) ? classes.buttonContainedAI : classes.buttonOutlined} ${(messagesAILength ===0 && ischatOpen ===1) && classes.CenterButtons2}`} >
                <span style={{paddingRight: 7, display: 'flex'}}>

                <TbRulerMeasure />
</span>
              Run AI
            </Button> */}
          </Box>
        </div>
        : <Before />}

<SignUp onSubmit={signupSubmit} isLoading={isLoading} zoomScreen={zoomScreen}/>
    </>
  );
};

export default Home;
