import React, { useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Paper, TextField, Modal } from "@material-ui/core";
import { HexColorPicker } from "react-colorful";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { RiListSettingsLine } from "react-icons/ri";

import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import "../../containers/Canvas.css";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
export function Item(props) {
  //  Sortable Item Div
  const {
    id,
    color,
    name,
    handlePolygonClick,
    selectedPolygonIndex,
    feet,
    updatePolygonByPolygonId,
  } = props;

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [polygonColor, setPolygonColor] = useState(color);
  const [polygonName, setPolygonName] = useState(name);

  let newFeet;
  if (feet) {
    newFeet = parseFloat(feet).toFixed(1) + "ft";
  }

  const handleSettingsModalOpen = () => {
    setSettingsModalOpen(true);
  };

  const handleSettingsModalClose = () => {
    setSettingsModalOpen(false);
  };

  const handleColorChange = () => {
    updatePolygonByPolygonId(id, "updateColor", { newColor: polygonColor });
  };

  const handleSavePolygonName = () => {
    updatePolygonByPolygonId(id, "updateName", { newName: polygonName });
    handleSettingsModalClose()
  };

  const handleDeletePolygon = () => {
    updatePolygonByPolygonId(id, "deletePolygon");
  };

  const handlePolygonNameChange = (event) => {
    setPolygonName(event.target.value);
  };

  const handleTextFieldKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      setPolygonName((prevPolygonName) => prevPolygonName + " ");
    }
  };

  useEffect(() => {
    if (selectedPolygonIndex) {
      const element = document.getElementById(selectedPolygonIndex);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, [selectedPolygonIndex]);
  return (
    <div
      className="sortableItem"
      onClick={() => handlePolygonClick(id)}
      style={{
        backgroundColor: selectedPolygonIndex === id && "#F0EBE3",
      }}
      id={id}
    >
      {name}
      <>
        <p style={{ fontWeight: 500 }}> {newFeet && newFeet}</p>

        <span
          style={{ backgroundColor: color }}
          className="groupColor colorwithnumber"
        ></span>
        <Button
          onClick={handleSettingsModalOpen}
          className="accordionHeaderSettingsBtn"
        >
          <SettingsOutlinedIcon style={{color:"white"}}/>
        </Button>
      </>
      <Modal open={settingsModalOpen} onClose={handleSettingsModalClose}>
        <Paper
          style={{
            display: "flex",
            gap: "2vh",
            flexDirection: "column",
            alignItems: "center",
            // width: 300,
            // height: 475,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "1.7vh",
          }}
        >
          <div className="modelSetting">

            <span style={{ fontSize: "larger", fontWeight: "bold" }}>Polygon Settings</span>
            <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={() => {
              handleSettingsModalClose();
              handleColorChange();
            }} />
          </div>
          <HexColorPicker
            style={{ minWidth: 300, minHeight: 200, marginTop: "-1.1vh" }}
            color={polygonColor}
            onChange={setPolygonColor}
          />

          <TextField
            id="editGroupName"
            autoFocus
            fullWidth
            label="Edit Polygon Name"
            variant="outlined"
            value={polygonName}
            onChange={handlePolygonNameChange}
            onKeyDown={handleTextFieldKeyDown}
          />
          <div className="modelSetting">
            <Button
              variant="contained"
              className="buttonModel"
              style={{ color: "#af0000" }}

              onClick={handleDeletePolygon}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
            <Button
              className="buttonModel"
              variant="contained"
              style={{ color: "#050596" }}
              onClick={handleSavePolygonName}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}

export default function SortableItem(props) {
  const { children, handlePosition = "right", id } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flex: 1,
    position: "relative",
  };

  let handleStyle = {
    position: "absolute",
    fontSize: 36,
    color: "black",
    cursor: "grab",
  };

  const rightHandleStyle = {
    right: 25,
    top: 0,
    bottom: 0,
    transform: "rotate(90deg)",
  };

  const topHandleStyle = {
    right: 40,
    top: 0,
  };

  if (handlePosition === "right") {
    handleStyle = {
      ...handleStyle,
      ...rightHandleStyle,
    };
  } else if (handlePosition === "top") {
    handleStyle = {
      ...handleStyle,
      ...topHandleStyle,
    };
  }
  return (
    <div draggable ref={setNodeRef} style={style} {...attributes}>
      <span
        style={{
          position: "absolute",
          height: "-webkit-fill-available",
          left: "5vh",
          width: "100%",
        }}
        {...listeners}
      >
        <DragIndicatorIcon style={{ color: "transparent" }} />
        {/* <img style={{ cursor: "pointer" }} src={dragSvg} alt="drag" /> */}
      </span>
      {children}
      {/* <div  style={handleStyle}>
        ...
      </div> */}
    </div>
  );
}
