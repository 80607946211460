import {
  SET_BACKGROUND_LAYOUT,
  SET_BACKGROUND_LAYOUT_LOADING,
  TOGGLE_PDF,
  SET_CURRENT_PDF_FİLE,
  SET_SCALE_DATA,
  SET_CURRENT_PAGE,
  SET_CURRENT_PDF_ID,
  SET_CURRENT_PDF_PATH,
  SET_CURRENT_PDF_FILE_LOCALL,
  SET_REFRESH_PDF_DATA_FLAG,
  SET_FIRST_UPLOAD,
  RESET_REDUX,
} from "../actions/types";

export const initialState = {
  isOpen: false,
  currentPdfID: null,
  currentPdf: null,
  currentPdfPath: null,
  scaleData: null,
  currentPage: null,
  currentPagePolygons: null,
  backgroundLayout: null,
  setBackgroundLayoutLoading: false,
  blobPDFlocal: null,
  refreshPdfDataFlag: false,
  firstUpload: false,
};
const pdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PDF_FILE_LOCALL: {
      return { ...state, blobPDFlocal: action.blobPDFlocal };
    }
    case TOGGLE_PDF: {
      return { ...state, isOpen: action.show };
    }
    case SET_CURRENT_PDF_ID: {
      return { ...state, currentPdfID: action.id };
    }
    case SET_REFRESH_PDF_DATA_FLAG: {
      return { ...state, refreshPdfDataFlag: action.refreshFlag };
    }
    case SET_CURRENT_PDF_FİLE: {
      return { ...state, currentPdf: action.pdf };
    }
    case SET_CURRENT_PDF_PATH: {
      return { ...state, currentPdfPath: action.path };
    }
    case SET_SCALE_DATA: {
      return { ...state, scaleData: action.data };
    }
    case SET_CURRENT_PAGE: {
      return { ...state, currentPage: action.page };
    }
    case SET_BACKGROUND_LAYOUT: {
      return { ...state, backgroundLayout: action.background };
    }
    case SET_BACKGROUND_LAYOUT_LOADING: {
      return { ...state, setBackgroundLayoutLoading: action.status };
    }
    case SET_FIRST_UPLOAD: {
      return { ...state, firstUpload: action.firstUpload };
    }
    case RESET_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default pdfReducer;
