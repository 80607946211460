import React, { useState } from "react";
import { Line, Circle, Group } from "react-konva";
import { minMax, dragBoundFunc } from "../utils";
/**
 *
 * @param {minMaxX} props
 * minMaxX[0]=>minX
 * minMaxX[1]=>maxX
 *
 */
const PolygonAnnotation = (props) => {
  const {
    points,
    flattenedPoints,
    isFinished,
    handlePointDragMove,
    handleGroupDragEnd,
    handlePointDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    isDraw,
    onDragStartMove,
    onClick,
    fillColor,
    selected,
    visible,
    polygonRef,
    pdfSize,
  } = props;
  const vertexRadius = 3; //5

  const [stage, setStage] = useState();
  const handleGroupMouseOver = (e) => {
    if (!isFinished) return;
    e.target.getStage().container().style.cursor = "move";
    setStage(e.target.getStage());
  };
  const handleGroupMouseOut = (e) => {
    e.target.getStage().container().style.cursor = "default";
  };
  const [minMaxX, setMinMaxX] = useState([0, 0]); //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); //min and max in y axis
  const handleGroupDragStart = (e) => {
    console.log("handleGroupDragStart");
    onDragStartMove(true);
    let arrX = points.map((p) => p[0]);
    let arrY = points.map((p) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };
  const groupDragBound = (pos) => {
    let { x, y } = pos;

    const stageWidth = pdfSize.width;
    const stageHeight = pdfSize.height;

    const scaleX = stage.scaleX();
    const scaleY = stage.scaleY();
    const stageX = stage.x();
    const stageY = stage.y();

    const minX = minMaxX[0] * scaleX;
    const maxX = minMaxX[1] * scaleX;
    const minY = minMaxY[0] * scaleY;
    const maxY = minMaxY[1] * scaleY;

    // Left border (X min)
    if (minX + x < stageX) {
      x = stageX - minX;
    }

    // Top border (Y min)
    if (minY + y < stageY) {

      y = stageY - minY;
    }

    // Righ border (X max)
    if (maxX + x > stageX + stageWidth * scaleX) {
      x = stageX + stageWidth * scaleX - maxX;
    }

    // Bottom border (Y max)
    if (maxY + y > stageY + stageHeight * scaleY) {
      y = stageY + stageHeight * scaleY - maxY;
    }

    return { x, y };
  };

  return (
    <Group
      name="polygon"
      draggable={!isDraw}
      visible={visible}
      onDragStart={handleGroupDragStart}
      onDragEnd={handleGroupDragEnd}
      dragBoundFunc={groupDragBound}
      onMouseOver={handleGroupMouseOver}
      onMouseOut={handleGroupMouseOut}
      onClick={onClick}
    >
      <Line
        points={flattenedPoints}
        stroke={"#A9A9A9"}
        ref={polygonRef}
        strokeWidth={1} //3
        // offsetX={isFinished ? 1.5 : 0}
        // offsetY={isFinished ? 1.7 : 0}
        closed={isFinished}
        opacity={0.6}
        fill={selected ? "#FFD700" : fillColor || "rgb(0,0,0,0.5)"}
      />
      {points.map((point, index) => {
        const x = point[0];
        const y = point[1];
        const startPointAttr =
          index !== 0 && !isDraw
            ? {
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint,
              }
            : {
                hitStrokeWidth: 12,
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint,
              };
        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={vertexRadius}
            fill={"rgb(71, 127, 179,0.4)"}
            stroke={"#4c516d"}
            strokeWidth={0.5} //3
            width={4}
            height={4}
            draggable={!isDraw}
            onDragMove={handlePointDragMove}
            onDragEnd={handlePointDragEnd}
            dragBoundFunc={(pos) =>
              dragBoundFunc(stage.width(), stage.height(), vertexRadius, pos)
            }
            {...startPointAttr}
          />
        );
      })}
    </Group>
  );
};

export default PolygonAnnotation;
