import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import newTGif from "../../images/tutorial/tc.mp4";
import { styled } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";

const FractionalBox = styled(Box)(({ fraction }) => ({
  flexBasis: `calc(${fraction} * 100% / 12)`,
}));

const useStyles = makeStyles((theme) => ({
  buttonContainer1: {
    width: "75%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  video: {
    width: '103%',
    height: '90vh',
    marginTop:"-11.6vh",
    objectFit: 'cover',
    borderRadius: 12,
  },
  leftSideVideo: {
    objectPosition: '12% center',  // Adjust this to control how much of the left side is shown
    width: '103%',
    height: '90vh',
    marginTop:"-11.6vh",
    objectFit: 'cover',
    borderRadius: 12,
  },
  rightSideVideo: {
    objectPosition: '86% center', // Adjust this to control how much of the right side is shown
    width: '103%',
    height: '90vh',
    marginTop:"-11.6vh",
    objectFit: 'cover',
    borderRadius: 12,
  },
}));

const Tutorial = () => {
  const classes = useStyles();

  return (
    <div>
      <div className="body-main" id="Home" style={{ marginTop: 0, minHeight: "100vh", justifyContent: "center" }}>
        <Grid container className={classes.buttonContainer1}>
          <FractionalBox
            id="Tools"
            fraction={2}
            className="shadowsColumn containerColl grid-center-main"
          >
            <span className="modelTitle">
              TOOLS
            </span>
            <div className={classes.videoContainer}>
              <video
                className={`${classes.leftSideVideo}`}
                data-aid="HEADER_VIDEO"
                type="video/mp4"
                autoPlay
                loop
                muted
                playsInline
                src={newTGif}
              ></video>
            </div>
          </FractionalBox>
          
          <FractionalBox
            id="Model"
            fraction={7.5}
            className="shadowsColumn containerColl grid-center-main"
          >
            <span className="modelTitle" style={{ textAlign: "center" }}>
              MODEL
            </span>
            <div className={classes.videoContainer}>
              <video
                className={classes.video}
                data-aid="HEADER_VIDEO"
                type="video/mp4"
                autoPlay
                loop
                muted
                playsInline
                src={newTGif}
              ></video>
            </div>
          </FractionalBox>
          
          <FractionalBox
            id="Measurements"
            fraction={2}
            className="shadowsColumn containerColl grid-center-main"
          >
            <span className="modelTitle">
              MEASUREMENTS
            </span>
            <div className={classes.videoContainer}>
              <video
                className={`${classes.rightSideVideo}`}
                data-aid="HEADER_VIDEO"
                type="video/mp4"
                autoPlay
                loop
                muted
                playsInline
                src={newTGif}
              ></video>
            </div>
          </FractionalBox>
        </Grid>
      </div>
    </div>
  );
};

export default Tutorial;
