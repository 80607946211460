import React,{useState} from 'react';
import { FaFileDownload } from "react-icons/fa";
import { makeStyles } from '@material-ui/core/styles';

import {  useSelector } from "react-redux";
const useStyles = makeStyles({
    iconSize:{
        fontSize: 26
          },
  });
const CSVDownloadButton = () => {
  const classes = useStyles();

    const CSVData = useSelector((state) => state.aiScaleRooms.CSVData);
    const [loading, setLoading] = useState(false);

    const convertToCSV = (data) => {
        // Filter containers
        const containers = data.filter(obj => obj.container);

        // Initialize CSV content
        let csvContent = '';

        // Loop through each container
        containers.forEach(container => {
            // Add container as top-level header
            csvContent += `${container.id}\n`;

            // Filter data for items under current container
            const filteredData = data
                .filter(obj => obj.parent === container.id && obj.name && obj.feet);

            // Add header row for name and feet
            csvContent += `Name,Feet\n`;

            // Add rows for each item under current container
            filteredData.forEach(obj => {
                csvContent += `${obj.name},${obj?.feet?.toFixed(2)}\n`;
            });

            // Add a blank line between containers (optional, remove if not needed)
            csvContent += '\n';
        });

        return csvContent;
    };

    // Function to handle CSV download
    const downloadCSV = () => {
        setLoading(true);
        setTimeout(() => {
            const csvData = convertToCSV(CSVData);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'constraAi.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
        }, 100); // simulate delay for demonstration
    };

  return (
    <>
    <FaFileDownload  className={classes.iconSize}  onClick={downloadCSV}/>
    {loading && (
                <div id="loading" style={loadingStyle}></div>
            )}
    </>
  );
};
const loadingStyle = {
    display: 'block',
    width: '30px',
    height: '30px',
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #3498db',
    borderRadius: '50%',
    animation: 'spin 2s linear infinite',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};
export default CSVDownloadButton;
