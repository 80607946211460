import React from "react";
import { Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import AppBar from "./layout/AppBar";
import "./App.css";
import "./Breakpoints.css";
// import ToolBar from "./layout/ToolBar";
// import GridContainer from "./layout/GridContainer";
import CoordinateToolTip from "./components/CoordinateToolTip";
import MouseToolTip from "./components/MouseToolTip";
import SetScaleModal from "./components/SetScaleModal";
import { isMobile } from "react-device-detect";
import logo from "./images/favicon.png";

import LandingPage from "./landingPage/LandingPage";
import { useSelector } from "react-redux";

import {
  Router,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import ErrorPage from "./landingPage/pages/ErrorPage";

function App() {
  const currentPdf = useSelector((state) => state.pdf.currentPdf);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#24292E",
      },
    },
  });

  // React.useEffect(() => {
  //   const ele = document.getElementById("ipl-progress-indicator");
  //   if (ele) {
  //     setTimeout(() => {
  //       // fade out
  //       ele.classList.add("available");
  //       setTimeout(() => {
  //         // remove from DOM
  //         ele.outerHTML = "";
  //       }, 10000);
  //     }, 3000);
  //   }
  // }, []);

  const router = createBrowserRouter([
    {
      path: "/:pdfID",
      element: <LandingPage />,
      loader: async ({ params }) => {
        try {
          const res = await fetch(
            `https://constra-api-srdsoovefq-uc.a.run.app/pdfs/${params.pdfID}`
          );

          if (!res.ok) {
            throw new Error("Something went wrong.");
          }

          const pdfData = await res.json();

          return pdfData;
        } catch (error) {
          return redirect("/404");
        }
      },
    },
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/404",
      element: <ErrorPage status={404} />,
    },
    {
      path: "/500",
      element: <ErrorPage status={500} />,
    },
    {
      path: "*",
      element: <ErrorPage status={404} />,
    },
  ]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        {!isMobile ? (
          <>
            <RouterProvider router={router}>
              <Router>
                <LandingPage />
              </Router>
            </RouterProvider>

            {/* <Grid container style={{ position: 'relative' }}>

                <Grid item xs={12}>
                  <AppBar />
                </Grid>

                <Grid item>
                  <ToolBar />
                </Grid>

                <Grid item xs style={{ height: 'calc(100vh - 64px)', overflow: 'scroll' }}>
                  <GridContainer />
                </Grid>

                <PdfViewer />
              </Grid>
*/}
            {currentPdf && (
              <>
                <CoordinateToolTip />
                <MouseToolTip />
                <SetScaleModal />
              </>
            )}
          </>
        ) : (
          <div style={{ position: "fixed", top: "30%", width: "100vw" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img width="112" height="112" src={logo} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
              }}
            >
              <Typography variant="h6">
                Coming soon to mobile platforms.
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 4,
              }}
            >
              <Typography variant="subtitle2">
                Constra is currently only available on desktop.
              </Typography>
            </div>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
