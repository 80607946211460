import React, { useState, useRef, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import "./slider.css";
import GetInTouch from "./GetInTouch";
import About from "./GetStarted";
import Home from "./Home";
import SignIn from "./SignIn";
import Tutorial from "./Tutorial";
import logo from "../../images/appLogo/Logoicon.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import IntroTour from '../../components/IntroTour';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {UserMenu} from '../components/UserMenu'
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const Slider = () => {
  // const introRef = useRef(null);
  const auth = useSelector((state) => state.auth.auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAppLoading, setIsAppLoading] = useState(false);
  const pages = [<Home />, <Tutorial />, <About />, <GetInTouch />,<SignIn />];
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  const pagesTitles = [
    { name: "Model", dec: "View and manage your data models" },
    {
      name: "Tutorial",
      dec: "Access step-by-step guides and tutorials",
    },
    { name: "About", dec: "Learn more about this application" },
    {
      name: "Contact",
      dec: "Get in touch with our support team",
    },
    { name: "Sign In", dec: "Sign In or Sign Up" },
  ];
  useEffect(() => {
    const ele = document.getElementById("ipl-progress-indicator");
    if (ele) {
      setTimeout(() => {
        // fade out
        ele.classList.add("available");
        setTimeout(() => {
          // remove from DOMd
          ele.outerHTML = "";
        }, 10000);
        setIsAppLoading(true);
      }, 3000);
    }
  }, []);
  return isAppLoading ? (
    <div>
      <div className="logoWithDot">
        <img src={logo} alt="Logo" className={"logo"} />
      </div>
      <div className="dots">
        {pagesTitles.map((title, index) => (
          <LightTooltip title={title.name.toLocaleUpperCase()} arrow>
            <span
              data-content={title.name}
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => {
                goToSlide(index);
              }}
            ></span>
          </LightTooltip>
        ))}
      </div>
       {auth && 
       <div className="starTour" style={{    right: "1.3%",  top: "1.3%"}}>
       <UserMenu />
       </div>
        }
      {/* <div className="starTour">
     {currentIndex === 0 && currentPdf && <Button
        variant="contained"
        color="primary"
        onClick={() => introRef.current.startTour()}
      >
        Start Tour
      </Button>}

      </div> */}
      <ToastContainer autoClose={10000} />
      <div className="slider">
        <div
          className="slides"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {pages.map((page, index) => (
            <div key={index} className="slide">
              {page}
            </div>
          ))}
        </div>
      </div>
      {/* <IntroTour ref={introRef} /> */}
    </div>
  ) : null;
};

export default Slider;
