import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { IoIosSend } from "react-icons/io";
import styled from "styled-components";
import { setAIMessages } from "../actions/chatAI";
import { useDispatch } from "react-redux";
import { MdAttachFile } from "react-icons/md";
import logo from "../images/appLogo/Logoicon.png";

const Container = styled.div`
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  z-index: 8;
  flex-direction: column;
  /* background-color: #33333394; */
  /* border: 1px solid #bd5e181a; */
  /* border-radius: 10px 10px 0 0; */
  transition: all 0.3s ease;
  /* position: ${({ isZoomed }) => (isZoomed ? "fixed" : "relative")};  */
  /* bottom: ${({ isZoomed }) => (isZoomed ? "0" : "0")}; 
  left: ${({ isZoomed }) => (isZoomed ? "0" : "0")};  */
  overflow: hidden;
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  padding-bottom: 0px;
  /* background-color: #041e3329; */
`;

const ChatBox = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 3px;
  border-radius: 5px;
  flex: 1;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${({ role }) =>
    role === "user" ? "flex-end" : "flex-start"};
  margin-bottom: 10px;
`;

const Message = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  max-width: 80%;
  word-break: break-word;
  background-color: ${({ role }) =>
    role === "user" ? "#032c4ca6" : "#ffffffa3"};
  color: ${({ role }) => (role === "user" ? "#fff" : "#000000")};
  text-align: ${({ role }) => (role === "user" ? "right" : "left")};
  font-size: ${({ isZoomed }) => (isZoomed ? "1.1em" : "0.875em")}; 
  box-shadow: ${({ role }) =>
    role === "user" ? "0 2px 5px rgba(0, 0, 0, 0.2)" : "0 2px 5px rgba(0, 0, 0, 0.1)"};
  margin: ${({ role }) => (role === "user" ? "0 0 10px auto" : "0 auto 10px 0")};
  align-self: ${({ role }) => (role === "user" ? "flex-end" : "flex-start")};
`;


const TypingIndicator = styled.div`
  color: #aaa;
  font-style: italic;
  padding: 8px 12px;
`;

const InputArea = styled.form`
  display: flex;
  align-items: center;
  /* border-radius: 1vh; */
  background-color: #333;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ddd;
  padding: 10px;
`;

const Input = styled.input`
  flex: 1;
  height: 40px;
  margin: 0 10px;
  padding: 0 10px;
  border: none;
  background-color: #333;
  color: #ddd;
  border-radius: 20px;
  &:focus-visible {
    outline: none;
  }
`;

const SendButton = styled(Button)`
  color: white;
`;
const DefaultMessagesContainer = styled.div`
   display: flex;
   width: -webkit-fill-available;
`;
const DefaultMessagesContainertop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  flex-grow: 1;
`;
const DefaultMessage = styled.span`
overflow: hidden;
      padding: 12px 20px;
    margin: 8px 5px;
    background-color: #2c3e50;
    color: white;
    display: flex;
    width: 20vh;
    height: 7vh;
    font-size:${({ isZoomed }) => (isZoomed ? "1.1em" : "0.875em")}; 
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    /* align-content: center; */
    justify-content: center;
    align-items: center;
  &:hover {
    background-color: #34495e;  // Slightly lighter on hover
  }
`;

const ChatBot = ({uploadPdfButtonClick}) => {
  const pdfId = useSelector((state) => state.pdf.currentPdfID);
  const page = useSelector((state) => state.pdf.currentPage);
  const messages = useSelector((state) => state.AIChat.messages);
  console.log(messages)
  const dispatch = useDispatch();

  const [input, setInput] = useState("");
  // const [messages, setMessages] = useState([]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [loading, setLoading] = useState(false);

  const chatBoxRef = useRef(null);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (input.trim()) {
      const userMessage = { role: "user", content: input };
      // setMessages((prevMessages) => [...prevMessages, userMessage]);
      dispatch(setAIMessages(userMessage))
      setInput("");
      setLoading(true);

      const userMessages = [...messages, userMessage].filter(
        (message) => message.role === "user"
      );

      try {
        const res = await fetch('https://constra-api-srdsoovefq-uc.a.run.app/analyze-floor-plans', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pdf_id: pdfId,
            page: page,
            messages: userMessages,
          }),
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        const data = await res.json();
        const botApiResponse = { role: "bot", content: data.analysis };

        // setMessages((prevMessages) => [...prevMessages, botApiResponse]);
      dispatch(setAIMessages(botApiResponse))

      } catch (error) {
        console.error("Error:", error);
        const botErrorResponse = { role: "bot", content: "Sorry, there was an error processing your request." };
        // setMessages((prevMessages) => [...prevMessages, botErrorResponse]);
      dispatch(setAIMessages(botErrorResponse))

      } finally {
        setLoading(false);
      }
    }
  };
  const handleDefaultMessageClick = async(content) => {
    if (content.trim()) {
      const userMessage = { role: "user", content: content };
      // setMessages((prevMessages) => [...prevMessages, userMessage]);
      dispatch(setAIMessages(userMessage))

      setInput("");
      setLoading(true);

      const userMessages = [...messages, userMessage].filter(
        (message) => message.role === "user"
      );

      try {
        const res = await fetch('https://constra-api-srdsoovefq-uc.a.run.app/analyze-floor-plans', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pdf_id: pdfId,
            page: page,
            messages: userMessages,
          }),
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        const data = await res.json();
        const botApiResponse = { role: "bot", content: data.analysis };

        // setMessages((prevMessages) => [...prevMessages, botApiResponse]);
      dispatch(setAIMessages( botApiResponse))

      } catch (error) {
        console.error("Error:", error);
        const botErrorResponse = { role: "bot", content: "Sorry, there was an error processing your request." };
        // setMessages((prevMessages) => [...prevMessages, botErrorResponse]);
      dispatch(setAIMessages(botErrorResponse))

      } finally {
        setLoading(false);
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission
      handleSubmit(e); // Call handleSubmit
    }
  };
  const defaultMessages = [
    { content: "Estimate the square footage of each room", type: "text" },
    { content: "Name the different materials called out", type: "text" },
    { content: "Identify the used scale. Example: SCALE", type: "text" },
    { content: "How many rooms we have and their names", type: "text" }
  ];

  return (
    <Container isZoomed={isZoomed}>
      <ChatContainer>
      {messages.length === 0 ? (
        <DefaultMessagesContainertop>
        <img src={logo} alt="Logo" className={"logo"} />
          <DefaultMessagesContainer messagesLength={messages.length}>
            {defaultMessages.map((msg, index) => (
              <DefaultMessage key={index} isZoomed={isZoomed} onClick={() => handleDefaultMessageClick(msg.content)}>
                {msg.content}
              </DefaultMessage>
            ))}
          </DefaultMessagesContainer>
            </DefaultMessagesContainertop>
        ) : (
          <ChatBox ref={chatBoxRef}>
            {messages.map((message, index) => (
              <MessageContainer key={index} role={message.role}>
                <Message role={message.role} isZoomed={isZoomed}>
                  {message.content}
                </Message>
              </MessageContainer>
            ))}
          </ChatBox>
        )}
         {loading && (
              <TypingIndicator>AI is typing...</TypingIndicator>
            )}
      </ChatContainer>

      <InputArea onSubmit={handleSubmit}>
        <Button onClick={() =>{uploadPdfButtonClick("click")}}>
        <MdAttachFile size={20} />
        </Button>
        <Input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
          onKeyDown={handleKeyDown} // Attach keydown handler
        />
        <SendButton type="submit">
          <IoIosSend size={24} />
        </SendButton>
      </InputArea>
    </Container>
  );
};

export default ChatBot;
