import { SET_AI_ROOM_DATA, SET_AI_SCALE_DATA, SET_CSV_DATA } from "./types";

export const setScaleData = (scaleData) => {
  return {
    type: SET_AI_SCALE_DATA,
    scaleData: scaleData,
  };
};

export const setAiRooms = (aiRooms) => {
  return {
    type: SET_AI_ROOM_DATA,
    aiRooms: aiRooms,
  };
};
export const setCSVData = (csv) => {
  return {
    type: SET_CSV_DATA,
    CSVData: csv,
  };
};