import { SET_MESSAGES_AI } from '../actions/types';

const initState = {
  messages: [],
}

const chatReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_MESSAGES_AI:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      }
    default:
      return state;
  }
}

export default chatReducer;