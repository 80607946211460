import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import "@fortawesome/fontawesome-free/css/all.css";
import { setTool } from "../actions/toolActions";
import {
  setAnchor,
  updateEdges,
  setCurShape,
  updateSelected,
} from "../actions/sheetActions";
import { useSelector, useDispatch } from "react-redux";
import { togglePDF } from "../actions/pdfActions";
import { drawPolygon, drawRectangle } from "../actions/polygonActions";
import { BiFileFind } from "react-icons/bi";
import { PiPolygonThin } from "react-icons/pi";

import { FaDrawPolygon } from "react-icons/fa";

import ShareModal from "./ShareModal"; // Import the ShareModal component
import { TbZoomInArea } from "react-icons/tb";
import { TbZoomOutArea } from "react-icons/tb";
import { FaShareSquare } from "react-icons/fa";
import { FaArrowPointer } from "react-icons/fa6";
import { ImUpload } from "react-icons/im";
import { BiDownload } from "react-icons/bi";
import { RiChatDownloadLine } from "react-icons/ri";

import CSVDownload from "./CSVDownload";
import { PiRectangleDashedDuotone } from "react-icons/pi";
import styled from "styled-components";

// Styled Components
const ToolBarContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
 
  width: -webkit-fill-available;
  background-color: #3333334d;
  /* padding: 10px 0; */
  z-index: 9999;
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
`;
const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: #444;
  margin: 8px 0;
`;
const IconButton = styled.button`
  color: #fff;
  font-size: 20px;
  height: 40px;
  margin: 4px;
  width: 40px;
  background-color: ${(props) => (props.active ? "#666" : "transparent")};
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #666;
  }
`;

function ToolBar({ ZoomInClick, ZoomOutClick,uploadPdfButtonClick }) {
  const dispatch = useDispatch();
  const currentTool = useSelector((state) => state.tool.current);
  const [open, setOpen] = useState(false);

 
 
  const handleOpen = () => {
    onClick("Share");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onClick = (toolName) => {
    dispatch(setTool(toolName));
    dispatch(togglePDF(toolName === "PDF"));
    dispatch(drawPolygon(toolName === "POLYGON"));
    dispatch(drawRectangle(toolName === "RECTANGLE"));
    dispatch(setAnchor(null));
    dispatch(setCurShape(null));
    dispatch(updateEdges([]));
    dispatch(updateSelected([]));
  };

  return (
    <ToolBarContainer>
      <Tooltip title={<span>File Select</span>} placement="bottom" arrow>
        <IconButton
          active={currentTool === "PDF"}
          onClick={() => onClick("PDF")}
        >
          <BiFileFind />
        </IconButton>
      </Tooltip>

      <Tooltip title={<span>Move</span>} placement="bottom" arrow>
        <IconButton
          active={currentTool === "POINTER"}
          onClick={() => onClick("POINTER")}
        >
          <FaArrowPointer />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span>Polygon</span>} placement="bottom" arrow>
        <IconButton
          active={currentTool === "POLYGON"}
          onClick={() => onClick("POLYGON")}
          id="polygon"
        >
          <PiPolygonThin />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span>Rectangle</span>} placement="bottom" arrow>
        <IconButton
          active={currentTool === "RECTANGLE"}
          onClick={() => onClick("RECTANGLE")}
          id="polygonRectangle"
        >
          <PiRectangleDashedDuotone />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span>Zoom In</span>} placement="bottom" arrow>
        <IconButton
          onClick={() => {
            ZoomInClick(true);
            onClick("ZoomIn");
          }}
          id="ZoomIn"
        >
          <TbZoomInArea />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span>Zoom Out</span>} placement="bottom" arrow>
        <IconButton
          onClick={() => {
            ZoomOutClick(true);
            onClick("ZoomOut");
          }}
          id="ZoomOut"
        >
          <TbZoomOutArea />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span>Share</span>} placement="bottom" arrow>
        <IconButton onClick={handleOpen} id="Share">
          <FaShareSquare />
        </IconButton>
      </Tooltip>
      <ShareModal open={open} handleClose={handleClose} />
      <Tooltip title={<span>Download</span>} placement="bottom" arrow>
        <IconButton id="Download">
          <RiChatDownloadLine />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span>Upload PDF </span>} placement="bottom" arrow>
        <IconButton id="Upload PDF"  onClick={() =>{uploadPdfButtonClick("click")}}>
          <ImUpload />
        </IconButton>
      </Tooltip>
      
    </ToolBarContainer>
  );
}

export default ToolBar;
