import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Snackbar, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: '#1c1c1c',
    color: '#e0e0e0',
    borderRadius:12,
    width: 400, // Adjust the width as needed
  },
  textField: {
    backgroundColor: '#333',
    '& .MuiInputBase-input': {
      color: '#e0e0e0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e0e0e0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#90caf9',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#90caf9',
    },
  },
  button: {
    color: '#90caf9',
  },
  snackbar: {
    '& .MuiAlert-root': {
      backgroundColor: '#333',
      color: '#e0e0e0',
    },
  },
}));

const ShareModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const shareLink = window.location.href; // Get the current URL

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="share-dialog-title" classes={{ paper: classes.dialog }}>
        <DialogTitle id="share-dialog-title">Share public link</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={shareLink}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className={classes.textField}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyLink} className={classes.button}>
            Copy Link
          </Button>
          <Button onClick={handleClose} className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        className={classes.snackbar}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareModal;
