import React, { useState, useEffect } from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Paper, TextField, Modal } from "@material-ui/core";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { HexColorPicker } from "react-colorful";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { RiListSettingsLine } from "react-icons/ri";

import SaveIcon from "@material-ui/icons/Save";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteIcon from "@material-ui/icons/Delete";

import SortableItem, { Item } from "./sortable_item";

const containerStyle = {
  display: "flex",
  minHeight: 50,
};

export const Container = React.forwardRef((props, ref) => {
  const {
    id,
    color,
    updateGroupByGroupId,
    showingGroups,
    expandedGroups,
    toggleExpandedGroup,
    items,
    defaultGroupID,
    setDefaultGroupID,
    toggleGroupVisibility,
    toggleDefaultGroup,
    children,
    row,
    style = {},
  } = props;

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [groupColour, setGroupColour] = useState(color);
  const [groupName, setGroupName] = useState(id);

  const handleSettingsModalOpen = () => {
    setSettingsModalOpen(true);
  };

  const handleSettingsModalClose = () => {
    setSettingsModalOpen(false);
  };

  const handleColorChange = () => {
    updateGroupByGroupId(id, "updateColor", { newColor: groupColour });
  };

  const handleSaveGroupName = () => {
    updateGroupByGroupId(id, "updateName", { newName: groupName });
    defaultGroupID === id && setDefaultGroupID(groupName);
  };

  const handleDeleteGroup = () => {
    updateGroupByGroupId(id, "deleteGroup");
  };

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleTextFieldKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      setGroupName((prevGroupName) => prevGroupName + " ");
    }
  };

  const handleGroupVisibility = () => {
    toggleGroupVisibility(id);
  };

  return (
    <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
      <AccordionItem dangerouslySetExpanded={expandedGroups?.includes(id)}>
        <AccordionItemHeading>
          <AccordionItemButton
            style={
              defaultGroupID === id
                ? { backgroundColor: "#666666" }
                : {}
            }
          >
            <div
              className="accordionHeader"
              onClick={() => toggleExpandedGroup && toggleExpandedGroup(id)}
            >
              <span className="accordion__buttonArrow"></span>
              <span
                className="accordionHeaderTitle"
                style={{ position: "absolute" }}
                onClick={() => toggleDefaultGroup(id)}
              >
                {id}
              </span>
            </div>
            <div className="accordionHeaderDetails">
              {/* <span className="groupCount">{items ? items.length : 0}</span> */}
              <span
                style={{ backgroundColor: color || "white", color: "black" }}
                className="groupColor colorwithnumber"
                onClick={() => toggleDefaultGroup(id)}
              >
                {items ? items.length : 0}
              </span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  onClick={handleSettingsModalOpen}
                  style={{ padding: "5px 0px" }}
                  className="accordionHeaderSettingsBtn"
                >
                  <SettingsOutlinedIcon  style={{color:"white"}}/>
                </Button>

                <Button
                  style={{ padding: "5px 0px", paddingTop: 0 }}
                  onClick={handleGroupVisibility}
                  className="accordionHeaderSettingsBtn"
                >
                  {showingGroups?.includes(id) ? (
                    <VisibilityOutlinedIcon style={{color:"white"}}/>
                  ) : (
                    <VisibilityOff style={{color:"white"}}/>
                  )}
                </Button>
              </div>
              <Modal
                open={settingsModalOpen}
                onClose={handleSettingsModalClose}
              >
                <Paper
                  style={{
                    display: "flex",
                    gap: "2vh",
                    flexDirection: "column",
                    alignItems: "center",
                    // width: 300,
                    // height: 475,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "1.7vh",
                  }}
                >
                  <div className="modelSetting">
                    <span style={{ fontSize: "larger", fontWeight: "bold" }}>
                      Group Settings
                    </span>
                    <CloseOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleSettingsModalClose();
                        handleColorChange();
                      }}
                    />
                  </div>
                  <HexColorPicker
                    style={{ minWidth: 300, minHeight: 200, marginTop: "-1.1vh" }}
                    color={groupColour}
                    onChange={setGroupColour}
                  />

                  <TextField
                    id="editGroupName"
                    autoFocus
                    fullWidth
                    label="Edit Group Name"
                    variant="outlined"
                    value={groupName}
                    onChange={handleGroupNameChange}
                    onKeyDown={handleTextFieldKeyDown}
                  />
                  <div className="modelSetting">
                    <Button
                      variant="contained"
                      className="buttonModel"
                      style={{ color: "#af0000" }}
                      onClick={handleDeleteGroup}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                    <Button
                      className="buttonModel"
                      variant="contained"
                      style={{ color: "#050596" }}
                      onClick={handleSaveGroupName}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  </div>
                </Paper>
              </Modal>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div
            ref={ref}
            style={{
              ...containerStyle,
              ...style,
              flexDirection: row ? "row" : "column",
            }}
          >
            <AccordionItemPanel>{children}</AccordionItemPanel>
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
});

export default function SortableContainer(props) {
  const {
    getItems,
    id,
    color,
    updateColor,
    updateGroupName,
    deleteGroup,
    updateGroupByGroupId,
    updatePolygonByPolygonId,
    row,
    setDefaultGroupID,
    defaultGroupID,
    handlePolygonClick,
    selectedPolygonIndex,
    toggleGroupVisibility,
    showingGroups,
    expandedGroups,
    toggleExpandedGroup,
    toggleDefaultGroup,
  } = props;

  const items = getItems(id);
  const itemIds = items.map((item) => item.id);

  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  if (isOver) {
    console.log("is over", id);
  }
  if (id === "AI Generated Rooms") {
    items
      .sort((a, b) => b.feet - a.feet)
      .forEach((room, index) => {
        room.name = `Room ${index + 1}`;
      });
  }
  return (
    <SortableItem
      id={id}
      color={color}
      updateColor={updateColor}
      updateGroupName={updateGroupName}
      deleteGroup={deleteGroup}
      handlePosition="top"
    >
      <Container
        ref={setNodeRef}
        row={row}
        id={id}
        setDefaultGroupID={setDefaultGroupID}
        defaultGroupID={defaultGroupID}
        color={color}
        updateGroupByGroupId={updateGroupByGroupId}
        updateColor={updateColor}
        updateGroupName={updateGroupName}
        deleteGroup={deleteGroup}
        showingGroups={showingGroups}
        expandedGroups={expandedGroups}
        toggleExpandedGroup={toggleExpandedGroup}
        toggleGroupVisibility={toggleGroupVisibility}
        toggleDefaultGroup={toggleDefaultGroup}
        items={items}
        style={{ backgroundColor: row ? "#cdcdcd" : "transparent" }}
      >
        <SortableContext
          items={itemIds}
          strategy={
            row ? horizontalListSortingStrategy : verticalListSortingStrategy
          }
        >
          {items.map((item) => {
            let child = (
              <Item
                id={item.id}
                color={item.color}
                name={item.name}
                feet={item.feet}
                handlePolygonClick={handlePolygonClick}
                selectedPolygonIndex={selectedPolygonIndex}
                updatePolygonByPolygonId={updatePolygonByPolygonId}
              />
            );

            if (item.container) {
              return (
                <SortableContainer
                  key={item.id}
                  id={item.id}
                  getItems={getItems}
                  row={item.row}
                  handlePosition="top"
                />
              );
            }

            return (
              <SortableItem key={item.id} id={item.id}>
                {child}
              </SortableItem>
            );
          })}
        </SortableContext>
      </Container>
    </SortableItem>
  );
}
