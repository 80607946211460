import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import { BounceLoader } from "react-spinners";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textField: {
    flex: 1,
    marginRight: '1.2vh',
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white", // Border color
      },
      "& input": {
        color: "rgb(191, 191, 191)", // Input text color
      },
    },
    "& .MuiFormLabel-root": {
      color: "rgb(191, 191, 191)", // Label text color
    },
  },
  message: {
    color: "rgb(191, 191, 191)", // Message text color
    fontSize: '1.9vh',
  },
  formInput: {
    padding: '0.3vh 0.6vh',
    width: "80%",
    height: '5vh',

    border: "0.1vh solid rgb(93 90 90)",
    borderRadius: "0.5vh",
    background: "transparent",
    outline: "none",
    lineHeight: "3vh",
    color: "white",
    fontSize: "2vh",
    fontWeight: 400,
    boxSizing: "border-box",
    "&:valid, &:focus": {
      border: "0.1vh solid rgb(191, 191, 191)",
    },
    "&::placeholder": {
      color: "rgb(93 90 90)",
    },
  },
  button: {
    fontSize: '1.7vh',
    backgroundColor: "#b0b0b0",
    color: "black",
    marginLeft: '1.6vh',
    padding: '1.1vh',
    width: '12vh',
    height: '5vh',
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.2vh',
      padding: '0.4vh 2.4vh',
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: '1vh',
      padding: '0.4vh 2vh',
    },
  },
  gridContainer: {
    display: "grid",
    gap: '2vh',
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1.4fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
    width: "-webkit-fill-available"
  },
}));

const SignupForm = ({ onSubmit, isLoading,zoomScreen }) => {
  const [email, setEmail] = useState("");
  const classes = useStyles();
  const currentPdf = useSelector((state) => state.pdf.currentPdf);
  const blobPDFlocal = useSelector((state) => state.pdf.blobPDFlocal);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(email);
  };

  return (
    <footer className={`${classes.footer}`}>
      <form onSubmit={handleSubmit} className="footer0 shadowsColumn" style={{
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px",
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px", width: (currentPdf || blobPDFlocal) ? (zoomScreen ?"78%" : "94%") : "65%", padding: "1.4vh 2vh"
      }}>
        <Box className={classes.gridContainer}>
          <Typography className={classes.message}>
            Enter your email to be the first to use our AI model for accurate
            plan review and dimensions for bidding, estimating and more.
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="end">
            <input
              className={classes.formInput}
              type="email"
              name="Email"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Enter your email"
              required
            />
            <Button
              variant="contained"
              type="submit"
              size="medium"
              className={classes.button}
            >
              {isLoading ? (
                <BounceLoader
                  color="#56586C"
                  loading={isLoading}
                  size={"2.5vh"} // 2.5vh converted from 25px
                  aria-label="Loading Spinner"
                />
              ) : (
                "Sign up"
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </footer>
  );
};

export default SignupForm;

