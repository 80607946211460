import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PiFilePdfThin } from "react-icons/pi";
import image from './../assets/about.png'; // Use the uploaded image

const items = [
  {
    id: 3,
    name: 'Project Plan Overview',
    imgSrc: image,
    date: '2024-08-22',
  },
  {
    id: 4,
    name: 'Client Feedback Report',
    imgSrc: image,
    date: '2024-08-21',
  },
  {
    id: 5,
    name: 'Marketing Strategy Q3 dfsdf sfsdf',
    imgSrc: image,
    date: '2024-08-20',
  },
  {
    id: 6,
    name: 'Sales Analysis Report',
    imgSrc: image,
    date: '2024-08-19',
  },
  {
    id: 7,
    name: 'Design Mockups V2',
    imgSrc: image,
    date: '2024-08-18',
  },
  {
    id: 8,
    name: 'Team Meeting Notes',
    imgSrc: image,
    date: '2024-08-17',
  },
  {
    id: 9,
    name: 'Financial Summary H1',
    imgSrc: image,
    date: '2024-08-16',
  },
  {
    id: 10,
    name: 'Product Roadmap',
    imgSrc: image,
    date: '2024-08-15',
  },
];

// Styles
const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
  },
  image: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(1),
  },
  itemName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    color: "white",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
    color: "white",
    fontSize: '1rem', // Adjust the icon size
  },
  itemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontSize: '0.875rem', // Smaller text size
  },
  itemDate: {
    marginTop: theme.spacing(0.5),
    color: "gray",
  },
  container: {
    overflow: "hidden",
    boxShadow: "0 0 0.5vh 0.5vh #00000020",
    backdropFilter: "blur(0.5vh)",
    backgroundColor: "#34343485",
    padding: theme.spacing(2),
    borderRadius: 8,
    textAlign: "center",
  },
  gridContainer: {
    overflowY: 'auto',
    padding: `0 ${theme.spacing(2)}px`, // Padding for left and right
    maxHeight: '90vh', // Restrict the max height to make it scrollable
  },
  bodyMain: {
    marginTop: "10vh",
    minHeight: "100vh",
    padding: theme.spacing(0, 2), // Additional padding for the entire container
  },
  comingSoon: {
    color: "white",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh', // Adjust as needed
    fontSize: '2rem',
    fontWeight: 'bold',
  },
}));

const ItemsList = () => {
  const classes = useStyles();

  return (
    <div className={classes.bodyMain}>
      <Typography className={classes.comingSoon}>
        Coming soon
      </Typography>
      <Grid container spacing={3} className={classes.gridContainer}>
        {items.map((item) => (
          <Grid item xs={2} sm={2} md={2} key={item.id}>
            <Paper className={classes.container} elevation={3}>
              <div className={classes.itemName}>
                <PiFilePdfThin className={classes.itemIcon} />
                <Typography variant="h6" className={classes.itemText}>
                  {item.name}
                </Typography>
              </div>
              <img src={item.imgSrc} alt={item.name} className={classes.image} />
              <Typography variant="body2" className={classes.itemDate}>
                {item.date}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ItemsList;
